import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { API_URL, ROUTE_URL } from 'constants.js'
import { post } from 'utils/api'

function TLPayRevolutSignupCompletePage() {
  const history = useHistory()
  const { signupId } = useParams()
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)

  useEffect(() => {
    (async () => {
      setGlobalSpinner(true)
      const authCode = new URLSearchParams(history.location.search).get('code')
      const { companyId } = await post(`${API_URL}/e-wallet/revolut-onboard/${encodeURIComponent(signupId)}/auth-code`, { authCode })
      return history.push(ROUTE_URL.tlpayRevolutSignup.replace(':companyId', encodeURIComponent(companyId)), {
        from: ROUTE_URL.tlpayRevolutSignupComplete,
      })
    })()
  }, [setGlobalSpinner, history, signupId])

  return <div />
}

TLPayRevolutSignupCompletePage.propTypes = {}

export default TLPayRevolutSignupCompletePage
