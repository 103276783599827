import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import AuthView from '../AuthView'
import { useStyles } from './styles'
import { useForm, Controller } from 'react-hook-form'
import { formSchema } from './formValidationSchema'
import { joiResolver } from '@hookform/resolvers/joi'
import SnackbarComponent from 'components/Snackbar'
import InviteFields from './components/InviteFields'
import ValidationGrid from './components/ValidationGrid'
import ValidationMessage from './components/ValidationMessage'
import CsvDropZone from './components/CsvDropZone'
import DividerWithText from 'components/DividerWithText'
import Button from 'components/Button'
import ModalImport from './components/ModalImport'
import { useIntl } from 'react-intl'

const CompanyInvite = ({
  location,
  currUserCompany,
  snackbar,
  sendInvites,
  validateInvites,
  failedInvites,
  totalFailedInvites
}) => {
  const isProcessingEntity = currUserCompany?.isProcessingEntity
  
  const { register, handleSubmit, formState: { errors }, control, watch, getValues } = useForm({ resolver: joiResolver(formSchema) })
  const watchFile = watch('file')

  const { formatMessage } = useIntl()

  const onSubmit = handleSubmit((data) => {
    sendInvites(data)
  })

  const snackBarMessage = snackbar?.isOpened && (
    snackbar.shouldReplaceWithCustomMessage
      ? (
        snackbar.isError
          ? snackbar.successOrErrorText.replace(
            'User already exists on the platform and is associated with',
            formatMessage({ id: 'invite-company.user-already-exists-on-the-platform-and-is-associated-with' }),
          )
          : `${formatMessage({ id: 'invite-company.invite-sent-to' })} "${getValues('name')}", ${getValues('email')}`
      )
      : snackbar.successOrErrorText
  )

  useEffect(() => {
    if (watchFile) {
      validateInvites(watchFile)
    }
  }, [ watchFile ])

  const classes = useStyles()
  return (
    <AuthView location={location} title={formatMessage({ id: 'invite-company.invite-partners' })} extra={isProcessingEntity ? <ModalImport /> : null}>
      <SnackbarComponent status={snackbar?.isOpened} error={snackbar?.isError} text={snackBarMessage} />
      <form onSubmit={onSubmit}>
        {isProcessingEntity ? (
          <Paper className={[ classes.root, classes.form ]}>
            <Controller
              name="file"
              control={control}
              render={({ field: { onChange } }) => (
                <CsvDropZone uploadCSV={onChange} />
              )}
            />
            { !watchFile ? (
              <>
                <DividerWithText>{formatMessage({ id: 'invite-company.or' })}</DividerWithText>
                <InviteFields errors={errors} register={register} />
              </>
            ) : null }
          </Paper>
        ) : (
          <Paper className={[ classes.root, classes.form ]}>
            <InviteFields errors={errors} register={register} />
          </Paper>
        )}
        
        <ValidationGrid data={failedInvites} total={totalFailedInvites} />
        <Paper className={[ classes.root, classes.form ]}>
          <ValidationMessage total={totalFailedInvites} />
          <Button
            disabled={totalFailedInvites >= 1000}
            className={classes.fab}
            color="primary"
            type="submit"
            icon="send"
          >
            {formatMessage({ id: 'invite-company.send-invite' })}
          </Button>
        </Paper>
      </form>
    </AuthView>
  )
}

CompanyInvite.propTypes = {
  location: PropTypes.object,
  snackbar: PropTypes.object,
  currUserCompany: PropTypes.object,
  sendInvites: PropTypes.func,
  validateInvites: PropTypes.func,
  failedInvites: PropTypes.array,
  totalFailedInvites: PropTypes.number
}

export default CompanyInvite
