import React, { useState } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Balances from './components/Balances'
import Transactions from './components/Transactions'
import Funding from './components/Funding'
import Withdrawal from './components/Withdrawal'
import { useIntl } from 'react-intl'
import { EWALLET_PROVIDER } from '../../constants'
import RevolutWithdrawal from './components/revolut/RevolutWithdrawal'
import RevolutFunding from './components/revolut/RevolutFunding'

const TLPay = ({ companyWallet }) => {
  const [accountSelected, setAccountSelected] = useState(null)
  const [tabSelected, setTabSelected] = useState(0)
  const { formatMessage } = useIntl()

  const handleSetTabSelected = (value) => {
    setTabSelected(value)
  }

  return (
    <>
      <Balances 
        accountSelected={accountSelected} 
        setAccountSelected={setAccountSelected} 
        companyWallet={companyWallet}
        />
      <Tabs value={tabSelected} onChange={(event, newValue) => setTabSelected(newValue)}>
        <Tab key={0} label={formatMessage({ id: 'tlpay.transactions' })} />
        <Tab key={1} label={formatMessage({ id: 'tlpay.funding' })} />
        <Tab key={2} label={formatMessage({ id: 'tlpay.withdraw' })} />
      </Tabs>
      {tabSelected === 0 && 
        <Transactions 
          accountSelected={accountSelected} 
          companyWallet={companyWallet} />}
      {tabSelected === 1 && (
        accountSelected?.provider === EWALLET_PROVIDER.REVOLUT
        ? <RevolutFunding
          accountSelected={accountSelected}
          companyWallet={companyWallet} />
        : <Funding
          accountSelected={accountSelected}
          companyWallet={companyWallet} />
      )}
      {tabSelected === 2 && (
        accountSelected?.provider === EWALLET_PROVIDER.REVOLUT
          ? <RevolutWithdrawal />
          : <Withdrawal
            accountSelected={accountSelected}
            handleSetTabSelected={handleSetTabSelected}
            companyWallet={companyWallet} />
      )}
    </>
  )
}

TLPay.propTypes = {
  companyWallet: PropTypes.object,
}

export default connect(TLPay) 
