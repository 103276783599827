import { connect } from 'react-redux'
import Component from './App.js'
import { withRouter } from 'react-router'
import { fetchCurrUserCompany } from 'store/companies'
import { getTokenExpiry, logout, refreshToken } from 'store/auth'
import { fetchRoutes, onNavigationChange } from 'store/routes'

export const mapState = ({ auth, companies }) => ({
  auth,
  company: companies.currUserCompany
})

export const mapDispatch = dispatch => ({
  logout: data => dispatch(logout(data)),
  refreshToken: data => dispatch(refreshToken(data)),
  fetchCurrUserCompany: data => dispatch(fetchCurrUserCompany(data)),
  getTokenExpiry: () => dispatch(getTokenExpiry()),
  fetchRoutes: () => dispatch(fetchRoutes()),
  navigate: (data) => dispatch(onNavigationChange(data))
})

export default withRouter(connect(mapState, mapDispatch)(Component))
