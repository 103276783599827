import React from 'react'

import DataGrid from 'components/DataGrid'
import { currencyFormatter, DEFAULT_COLUMN_WIDTH_VALUES, FILTER_TYPES } from 'components/DataGrid/utils'
import { DEFAULT_HIDDEN_COLUMNS, initialFilterValues } from '../constants'
import { useState } from 'react'

import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { API_URL, COMPANY_ID_TYPES, CURRENCY_CODE, CURRENCY_CODE_TO_NAME, TransType } from 'constants.js'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { filterValuesToQueryParams } from '../utils'
import { InstantPaymentStatus } from '@travel-ledger/type-constants'
import { kebabCase } from 'lodash'

const InstantPaymentsTable = () => {
  const [filters, setFilters] = useState(initialFilterValues)
  const queryParams = filterValuesToQueryParams(filters)
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [sortingOrder, setSortingOrder] = useState({})
  const { formatMessage } = useIntl()

  const query = {
    ...queryParams,
    ...sortingOrder,
    page,
    itemsPerPage,
  }
  const { isLoading, data, refetch } = useQuery(
    `instant-payments-table-${JSON.stringify(query)}`,
    () => get(`${API_URL}/instant-payments`, query),
    {
      enabled: true,
      retry: false,
    },
  )

  useEffect(() => {
    refetch()
  }, [filters, refetch])

  const Amount = ({ amount, currency }) => {
    return currencyFormatter({ value: amount, currency })
  }

  const handlePageChange = ({ currentPage, pageSize, sorting }) => {
    setPage(currentPage + 1)
    setItemsPerPage(pageSize)
    setSortingOrder(sorting)
  }

  const columns = [
    {
      title: formatMessage({ id: 'instant-payments.payer-code' }),
      name: 'payerIdReq',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.payer-code-type' }),
      name: 'payerIdType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(COMPANY_ID_TYPES)
              .map(([k, v]) => [k, formatMessage({ id: `companies.${kebabCase(v)}` })])
          ),
        },
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.payer-company-name' }),
      name: 'payerCompanyName',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.payee-code' }),
      name: 'payeeIdReq',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.payee-code-type' }),
      name: 'payeeIdType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries(COMPANY_ID_TYPES)
              .map(([k, v]) => [k, formatMessage({ id: `companies.${kebabCase(v)}` })])
          ),
        },
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.payee-company-name' }),
      name: 'payeeCompanyName',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.status' }),
      name: 'status',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries({
              ALL: 'ALL',
              ...InstantPaymentStatus,
            })
              .map(([k, v]) => [k, formatMessage({ id: `instant-payment-status-options.${kebabCase(v)}` })])
          ),
        },
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.currency' }),
      name: 'currency',
      width: DEFAULT_COLUMN_WIDTH_VALUES.S,
      filter: {
        type: FILTER_TYPES.MULTIPLE_SELECT,
        inputProps: {
          options: {
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.GBP],
            [CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR]]: CURRENCY_CODE_TO_NAME[CURRENCY_CODE.EUR],
          },
        },
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.amount' }),
      name: 'amountInCents',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      summaryCurrency: filters.currency?.length === 1 ? filters.currency?.[0] : null,
      summary: () => {
        if (filters.currency?.length !== 1) return null

        const currency = filters.currency?.[0]
        const totalAmountInCents = currency && data?.summary?.totalAmountInCents?.[currency]
        return totalAmountInCents
      },
      getCellValue: row => <Amount amount={row.amountInCents} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.actual-amount' }),
      name: 'actualAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      summaryCurrency: filters.currency?.length === 1 ? filters.currency?.[0] : null,
      summary: () => {
        if (filters.currency?.length !== 1) return null

        const currency = filters.currency?.[0]
        const totalAmountInCents = currency && data?.summary?.totalAmountInCents?.[currency]
        const totalRefundAmountInCents = currency && data?.summary?.totalRefundAmountInCents?.[currency]
        return totalAmountInCents - totalRefundAmountInCents
      },
      getCellValue: row => <Amount amount={row.actualAmount} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.payment-tx-id' }),
      name: 'paymentTransactionId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.payment-tx-ext-id' }),
      name: 'paymentTransactionExternalId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-ref' }),
      name: 'bookingRef',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.departure-date' }),
      name: 'departureDate',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: {
        type: FILTER_TYPES.DATE,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.trans-type' }),
      name: 'transType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: {
        type: FILTER_TYPES.SELECT,
        inputProps: {
          options: Object.fromEntries(
            Object.entries({
              ALL: 'ALL',
              ...TransType,
            })
              .map(([, v]) => [v, formatMessage({ id: `trans-type-options.${kebabCase(v)}` })])
          ),
        },
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.lead-name' }),
      name: 'leadName',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.requested-at' }),
      name: 'requestedAt',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'datetime',
      filter: {
        type: FILTER_TYPES.DATE,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.confirmed-at' }),
      name: 'confirmedAt',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'datetime',
      filter: {
        type: FILTER_TYPES.DATE,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.refund-amount' }),
      name: 'refundAmountInCents',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      summaryCurrency: filters.currency?.length === 1 ? filters.currency?.[0] : null,
      summary: () => {
        if (filters.currency?.length !== 1) return null

        const currency = filters.currency?.[0]
        const totalRefundAmountInCents = currency && data?.summary?.totalRefundAmountInCents?.[currency]
        return totalRefundAmountInCents
      },
      getCellValue: row => <Amount amount={row.refundAmountInCents} currency={row.currency} />,
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.refund-tx-id' }),
      name: 'refundTransactionId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.refund-tx-ext-id' }),
      name: 'refundTransactionExternalId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.refunded-at' }),
      name: 'refundedAt',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'datetime',
      filter: {
        type: FILTER_TYPES.DATE,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.reverse-tx-id' }),
      name: 'reverseTransactionId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.reverse-tx-ext-id' }),
      name: 'reverseTransactionExternalId',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.reversed-at' }),
      name: 'reversedAt',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'datetime',
      filter: {
        type: FILTER_TYPES.DATE,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.description' }),
      name: 'description',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.optional-1' }),
      name: 'optional1',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.optional-2' }),
      name: 'optional2',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.optional-3' }),
      name: 'optional3',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.dest-country' }),
      name: 'destCountry',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.destination' }),
      name: 'destination',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.trans-product-type' }),
      name: 'transProductType',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.trans-pax-no' }),
      name: 'transPaxNo',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      align: 'left',
      filter: {
        type: FILTER_TYPES.NUMBER,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.conf-number' }),
      name: 'confNumber',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.tkt-vchr-number' }),
      name: 'tktVchrNumber',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-sell-amount' }),
      name: 'bookingSellAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-com-amount' }),
      name: 'bookingComAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-nett-amount' }),
      name: 'bookingNettAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-sell-tax-amount' }),
      name: 'bookingSellTaxAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-com-tax-amount' }),
      name: 'bookingComTaxAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.booking-nett-tax-amount' }),
      name: 'bookingNettTaxAmount',
      width: DEFAULT_COLUMN_WIDTH_VALUES.M,
      type: 'currency',
      align: 'left',
      filter: {
        type: FILTER_TYPES.CURRENCY,
      },
    },
    {
      title: formatMessage({ id: 'instant-payments.buyer-notes' }),
      name: 'buyerNotes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.seller-notes' }),
      name: 'sellerNotes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.flight-numbers' }),
      name: 'flightNumbers',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.airline-codes' }),
      name: 'airlineCodes',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
    {
      title: formatMessage({ id: 'instant-payments.booked-by' }),
      name: 'bookedBy',
      width: DEFAULT_COLUMN_WIDTH_VALUES.L,
      filter: true,
    },
  ]
    .filter(Boolean)
    .map((column) => ({
      ...column,
      getCellValueToExport: column.name ? (row) => row[column.name] : undefined,
    }))

  return (
    <DataGrid
      data={data?.data || []}
      name="Instant Payments"
      fullRemainingWindowHeight
      isLoading={isLoading}
      filtering={{
        defaultFilters: initialFilterValues,
        filters,
        onChange: (newValue) => {
          setFilters(newValue)
        },
        clearFilters: () => {
          setFilters(initialFilterValues)
        },
      }}
      pagination={{
        pageSizes: [20, 50, 100],
        pageSize: 20,
        totalNumberOfItems: data?.total,
        onPageChanged: handlePageChange,
      }}
      columns={columns}
      extra={<RefreshButton onClick={refetch} />}
      defaultHiddenColumns={DEFAULT_HIDDEN_COLUMNS}
      {...{
        tableMessages: {
          noData: formatMessage({ id: 'table.no-data' }),
        },
      }}
    />
  )
}

export default InstantPaymentsTable
