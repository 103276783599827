import { Box, CircularProgress } from '@material-ui/core'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { post } from 'utils/api'
import { toast } from 'react-toastify'
import { styled } from '@material-ui/core/styles'

const Ol = styled('ol')(({ theme }) => ({
  '& > li:not(:last-child)': {
    paddingBottom: theme.spacing(1),
  }
}))

const EnableRevolutAccess = ({ signupId, onComplete, onCancel }) => {
  const { formatMessage } = useIntl()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleComplete = async () => {
    setIsSubmitting(true)
    try {
      await post(`/e-wallet/revolut-onboard/${encodeURIComponent(signupId)}/complete`, {})
      toast.success(formatMessage({ id: 'tlpay.revolut-access-verified' }), {
        autoClose: 2000,
      })
      onComplete()
    } catch (e) {
      toast.error(formatMessage({ id: 'tlpay.failed-to-obtain-revolut-access' }), {
        autoClose: 2000,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Box>
      <Box mt={2}>
        <Ol>
          <li>{formatMessage({ id: 'tlpay.click-enable-access-on-the-revolut-screen' })}</li>
          <li>{formatMessage({ id: 'tlpay.on-the-next-screen-click-authorize' })}</li>
          <li>{formatMessage({ id: 'tlpay.you-will-be-redirected-back-to-your-tl-pay-account-where-you-will-see-your-revolut-account-balance' })}</li>
        </Ol>
      </Box>

      <Box mt={4} display="flex" gridGap="1rem" justifyContent="flex-end">
        <Button onClick={() => onCancel?.()}>
          {formatMessage({ id: 'tlpay.back' })}
        </Button>
        <Button
          color="primary"
          onClick={handleComplete}
        >{
          isSubmitting
            ? <CircularProgress size="1rem" color="primary" />
            : formatMessage({ id: 'tlpay.finish' })
        }</Button>
      </Box>
    </Box>
  )
}

EnableRevolutAccess.propTypes = {
  signupId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default EnableRevolutAccess
