import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import DataGrid from 'components/DataGrid'
import { Link, Typography, Box, LinearProgress, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { download, post } from 'utils/api'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import HighlightOff from '@material-ui/icons/HighlightOff'
import { useStyles } from './styles'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { DATA_DELIVERY_STATUSES, DATA_DELIVERY_STATUSES_TO_PERCENTAGES } from 'constants.js'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const DataDeliveryStatusCell = ({ status }) => {
  const classes = useStyles()
  if (status === DATA_DELIVERY_STATUSES.LOADED) {
    return <CheckCircleIcon className={classes.successIcon} />
  }
  if (status === DATA_DELIVERY_STATUSES.REJECTED) {
    return <ErrorIcon color="error" />
  }
  if (status === DATA_DELIVERY_STATUSES.DELETED) {
    return <HighlightOff color="error" />
  }
  return <LinearProgressWithLabel value={DATA_DELIVERY_STATUSES_TO_PERCENTAGES[status]} />
}

const columns = formatMessage => [
  { name: 'uploadType', title: formatMessage({ id: 'data-delivery.upload-method' }) },
  { name: 'fileType', title: formatMessage({ id: 'data-delivery.file-type' }) },
  { name: 'createdAt', title: formatMessage({ id: 'data-delivery.date-time' }), type: 'datetime' },
  { name: 'filename', title: formatMessage({ id: 'data-delivery.file-name' }) },
  { name: 'sender', title: formatMessage({ id: 'data-delivery.account-from' }) },
  {
    name: 'importedInvoices',
    title: formatMessage({ id: 'data-delivery.number-of-records' }),
    getCellValue: (row) => row.importedInvoices ?? parseInt(row.importedInvoices, 10),
  },
  { name: 'totalAmount', title: formatMessage({ id: 'data-delivery.total-value' }), type: 'currency', align: 'right' },
  {
    name: 'dataDeliveryStatus',
    title: formatMessage({ id: 'data-delivery.status' }),
    getCellValue: (row) => <DataDeliveryStatusCell status={row.dataDeliveryStatus} />
  },
  { name: 'sameAmountDupes', title: formatMessage({ id: 'data-delivery.duplicates-with-same-amount' }) },
  { name: 'differentAmountDupes', title: formatMessage({ id: 'data-delivery.duplicates-with-different-amount' }) },
  {
    name: 'url',
    title: formatMessage({ id: 'data-delivery.download' }),
    getCellValue: (row) =>
      !!row.url && (
        <Link component="button" onClick={() => download(row.url)} >
          Download
        </Link>
      ),
    export: false,
  },
]

const DataDeliveryLogTable = ({ data, fetchDataDelivery, ...props }) => {
  const { formatMessage } = useIntl()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const idsToDeleteRef = React.useRef([])
  const rowLookup = useMemo(() => new Map(
    (data ?? []).map(r => [r.id, r])
  ), [data])

  const handleTransactionsDelete = async () => {
    try {
      if (idsToDeleteRef.current?.length) {
        await post('/data-delivery/delete', idsToDeleteRef.current)
        await fetchDataDelivery()
      }

      idsToDeleteRef.current = []
      setIsDeleteDialogOpen(false)
      toast.success(formatMessage({ id: 'data-delivery.deleted-data-delivery-records-successfully' }), {
        autoClose: 4000,
      })
    } catch {
      toast.error(formatMessage({ id: 'data-delivery.failed-to-delete-data-delivery-records' }), {
        autoClose: 4000,
      })
    }
  }

  return (<>
    <DataGrid
      columns={columns(formatMessage)}
      data={data ?? []}
      extra={<RefreshButton onClick={() => fetchDataDelivery()} />}
      {...props}
      sort={{
        keySorting: {
          order: 'DESC',
          orderBy: 'createdAt',
        },
      }}
      selectable={{
        actions: [{
          title: formatMessage({ id: 'data-delivery.delete-file' }),
          icon: 'delete',
          validate: selection => {
            if (selection.some(id => rowLookup.get(id)?.hasActiveCycle)) {
              throw new Error(formatMessage({ id: 'data-delivery.buyer-review-window-has-started' }))
            }
            return true
          },
          onClick: selectedIds => {
            idsToDeleteRef.current = selectedIds.filter(id => rowLookup.get(id))
            setIsDeleteDialogOpen(true)
          },
        }],
      }}
    />

    {isDeleteDialogOpen && (
      <Dialog open onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogContent>
          <Typography component="h4" variant="body">
            {formatMessage({ id: 'data-delivery.are-you-sure-you-want-to-delete-this-file-upload' })}
          </Typography>
          <Box m={2} maxHeight="10rem" overflow="scroll">
            {idsToDeleteRef.current?.map(id => (
              <Box key={id} m={1}>{rowLookup.get(id).filename}</Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="default"
          >
            {formatMessage({ id: 'data-delivery.back' })}
          </Button>
          <Button
            variant="contained"
            onClick={handleTransactionsDelete}
            color="primary"
          >
            {formatMessage({ id: 'data-delivery.confirm' })}
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </>)
}

DataDeliveryLogTable.propTypes = {
  data: PropTypes.array.isRequired,
}

export default DataDeliveryLogTable
