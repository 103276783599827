import * as React from 'react'

const NiumIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 140 41"
    {...props}
  >
    <path
      fill="#0B0E58"
      d="M104.926 3.101V37.93h6.286V13.974h.17l10.363 17.5h.68l10.703-17.5h.17V37.93h6.286V3.1h-7.135l-9.684 17.5h-.17L112.401 3.1h-7.475Zm-104.147 0V37.93h6.626V14.314h.17l16.14 23.616h6.966V3.1h-6.626v23.276h-.17L7.915 3.1H.779Zm62.522 21.577c0 9.005 5.437 14.101 14.611 14.101 8.495 0 14.781-5.097 14.781-14.101V3.101h-6.966v21.577c0 4.247-2.038 7.815-7.815 7.815-5.776 0-7.645-4.077-7.645-8.325V3.101H63.3v21.577ZM44.103 3.101V37.93h6.966V3.1h-6.966Z"
    />
  </svg>
)
export default NiumIcon
