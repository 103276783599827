import { connect } from 'react-redux'
import { onNavigationChange } from "store/routes"
import Component from './SettlementScheduleEdit'

const mapState = ({ auth, companies }) => ({
  currUserCompany: companies.currUserCompany,
  auth,
  push: onNavigationChange
})

const mapDispatch = dispatch => ({
  push: (data) => dispatch(onNavigationChange(data))
})

export default connect(mapState, mapDispatch)(Component)
