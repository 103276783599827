import { Box, CircularProgress, TextField } from '@material-ui/core'
import Button from 'components/Button'
import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { post } from 'utils/api'
import { toast } from 'react-toastify'
import { API_URL } from '../../../../constants'
import { styled } from '@material-ui/core/styles'

const Ol = styled('ol')(({ theme }) => ({
  '& > li:not(:last-child)': {
    paddingBottom: theme.spacing(1),
  }
}))

const ConfigureClientId = ({
  clientId: initClientId,
  signupId,
  onComplete,
  onCancel,
  onClientIdChange,
}) => {
  const { formatMessage } = useIntl()
  const [clientId, setClientId] = React.useState(initClientId)
  const [isSubmittingClientId, setIsSubmittingClientId] = React.useState(false)

  const updateClientId = React.useCallback(async () => {
    await post(`${API_URL}/e-wallet/revolut-onboard/${encodeURIComponent(signupId)}`, { clientId })
    onClientIdChange(clientId)
  }, [clientId, signupId, onClientIdChange])

  const handleComplete = async () => {
    setIsSubmittingClientId(true)
    try {
      await updateClientId()
      toast.success(formatMessage({ id: 'tlpay.successfully-submitted-client-id' }), {
        autoClose: 2000,
      })
      setIsSubmittingClientId(false)
      onComplete()
    } catch (e) {
      toast.error(formatMessage({ id: 'tlpay.failed-to-submit-client-id-please-try-again-later' }), {
        autoClose: 2000,
      })
      setIsSubmittingClientId(false)
    }
  }

  return (
    <Box>
      <Box pb={1}>
        <Ol>
          <li>{formatMessage({ id: 'tlpay.locate-your-client-id-it-will-be-displayed-in-your-revolut-account-if-you-followed-the-instructions-in-the-previous-step' })}</li>
          <li>{formatMessage({ id: 'tlpay.copy-the-client-id-select-the-client-id-in-your-revolut-account-and-copy-it' })}</li>
          <li>{formatMessage({ id: 'tlpay.paste-the-client-id-paste-the-copied-client-id-into-the-client-id-field-below' })}</li>
          <li>{formatMessage({ id: 'tlpay.proceed-to-the-next-step-to-complete-the-account-link' })}</li>
        </Ol>
      </Box>
      <Box display="flex" mt={2}>
        <TextField
          variant="outlined"
          label={formatMessage({ id: 'tlpay.client-id' })}
          value={clientId}
          onChange={e => setClientId(e.target.value)}
          disabled={isSubmittingClientId}
          required
          fullWidth
        />
      </Box>

      <Box mt={4} display="flex" gridGap="1rem" justifyContent="flex-end">
        <Button onClick={() => onCancel?.()}>
          {formatMessage({ id: 'tlpay.back' })}
        </Button>
        <Button
          color="primary"
          onClick={handleComplete}
          disabled={!clientId}
        >{
          isSubmittingClientId
            ? <CircularProgress size="1rem" color="primary" />
            : formatMessage({ id: 'tlpay.continue' })
        }</Button>
      </Box>
    </Box>
  )
}

ConfigureClientId.propTypes = {
  clientId: PropTypes.string.isRequired,
  signupId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClientIdChange: PropTypes.func.isRequired,
}

export default ConfigureClientId
