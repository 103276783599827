import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import PublishIcon from '@material-ui/icons/Publish'
import { TransactionsSummaryStatus } from '@travel-ledger/type-constants'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { downloadInvoices, downloadRemittances } from 'services/paymentsStatementApi'

const SummaryExportButton = ({ buttonRef, summaryId, summaryStatus }) => {
  const isCompleted = summaryStatus === TransactionsSummaryStatus.Completed
  const { formatMessage } = useIntl()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleExportRemittances = async () => {
    await downloadRemittances(summaryId)
    handleClose()
  }
  const handleExportInvoices = async () => {
    await downloadInvoices(summaryId)
    handleClose()
  }
  // ! TODO
  // const handleExportTransactions = async () => {
  //   await downloadTransactions(summaryId)
  //   handleClose()
  // };
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { remittancesTooltipTitle, remittancesAriaLabel } = isCompleted ? {
    remittancesTooltipTitle: '',
    remittancesAriaLabel: formatMessage({ id: 'payment-statement-export-button.remittances.aria-label' }),
  } : {
    remittancesTooltipTitle: formatMessage({ id: 'payment-statement-export-button.remittances-disabled-tooltip'}),
    remittancesAriaLabel: `${formatMessage({ id: 'payment-statement-export-button.remittances.aria-label'})} (${formatMessage({ id: 'payment-statement-export-button.remittances.disabled-tooltip'})})`,
  }

  return (
    <div>
      <Button
        ref={buttonRef}
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClick}
        style={{ borderRadius: '5px' }}
        startIcon={<PublishIcon />}
        aria-label={formatMessage({ id: 'payment-statement-export-button.aria-label' })}
      >
        <FormattedMessage id="payment-statement-export-button.label" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* Span wrapper is required for tooltips to work on disabled elements in Material-UI
            See: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
        <Tooltip title={remittancesTooltipTitle}>
          <span>
            <MenuItem
              onClick={handleExportRemittances}
              disabled={!isCompleted}
              aria-label={remittancesAriaLabel}
            >
              <FormattedMessage id="payment-statement-export-button.remittances.label" />
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem
          onClick={handleExportInvoices}
          aria-label={formatMessage({ id: 'payment-statement-export-button.invoices.aria-label' })}
        >
          <FormattedMessage id="payment-statement-export-button.invoices.label" />
        </MenuItem>
      </Menu>
    </div>
  )
}

SummaryExportButton.propTypes = {
  buttonRef: PropTypes.func,
  summaryId: PropTypes.string,
  summaryStatus: PropTypes.oneOf(Object.values(TransactionsSummaryStatus)),
}

export default SummaryExportButton
