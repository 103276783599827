import React from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useStyles } from './styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import RevolutIcon from './components/RevolutIcon'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import Button from 'components/Button'
import { useHistory } from 'react-router'
import { ROUTE_URL } from '../../constants'
import Modal from 'components/Modal'

const REVOLUT_BUSINESS_SIGNUP_URL = 'https://business.revolut.com/signup?promo=referabusiness&ext=travellejl '

function ConfirmDialog({ onCancel, onConfirm }) {
  const { formatMessage } = useIntl()
  const [value, setValue] = React.useState('')

  return (
    <Modal
      title={
        <Box fontSize="1.5rem">
          {formatMessage({ id: 'tlpay.please-confirm-if-you-already-have-an-active-revolut-account-for-your-business' })}
        </Box>
      }
      defaultOpened={true}
      onClose={onCancel}
      maxWidth="sm"
    >
      <div>
        <Box>{formatMessage({ id: 'tlpay.to-use-tl-pay-with-your-business-you-ll-need-a-revolut-business-account-if-you-already-have-one-simply-select-yes-to-link-it-to-your-tl-pay-account' })}</Box>
        <Box mt={2}>{formatMessage({ id: 'tlpay.if-you-don-t-have-a-revolut-business-account-yet-select-no-to-be-redirected-to-revolut-s-website-where-you-can-easily-create-one-once-your-revolut-account-is-set-up-return-to-tl-pay-and-select-yes-to-complete-the-linking-process' })}</Box>
        <Box mt={4}>
          <FormControl>
            <FormLabel>{formatMessage({ id: 'tlpay.please-select-which-applies-to-you' })}</FormLabel>
            <Box mt={1}>
              <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label={formatMessage({ id: 'tlpay.no-i-need-to-create-an-revolut-account' })}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label={formatMessage({ id: 'tlpay.yes-i-already-have-a-revolut-account' })}
                />
              </RadioGroup>
            </Box>
          </FormControl>
        </Box>
      </div>
      <Box mt={4} textAlign="end">
        <Button onClick={onCancel}>
          {formatMessage({ id: 'tlpay.cancel' })}
        </Button>
        <Button
          color="primary"
          marginLeft={2}
          onClick={() => onConfirm(value === 'yes')}
          disabled={!value}
        >
          {formatMessage({ id: 'tlpay.confirm' })}
        </Button>
      </Box>
    </Modal>
  )
}

function Introduction({
  goToNextStep,
}) {
  const { formatMessage } = useIntl()
  const [shouldShowExistingAccountDialog, setShouldShowExistingAccountDialog] = React.useState(false)

  return (<>
    <Box width="10rem" maxHeight="5rem" my={2}>
      <RevolutIcon style={{ width: '100%', height: '100%' }} />
    </Box>
    <Typography paragraph>
      {formatMessage({ id: 'tlpay.to-use-revolut-for-your-tl-pay-settlements-you-ll-need-a-revolut-account-if-you-don-t-have-one-you-can-easily-create-one-by-clicking-the-create-revolut-account-button-below-this-will-redirect-you-to-the-revolut-website-to-complete-the-account-creation-process-alternatively-if-you-already-have-a-revolut-account-simply-click-i-already-have-a-revolut-account-and-follow-the-account-link-process-to-connect-your-existing-account-and-start-receiving-tl-pay-settlements' })}
    </Typography>
    <Box mt={4}>
      <Button color="primary" onClick={() => setShouldShowExistingAccountDialog(true)}>
        {formatMessage({ id: 'tlpay.start-application' })}
      </Button>
    </Box>

    {shouldShowExistingAccountDialog && (
      <ConfirmDialog
        onCancel={() => setShouldShowExistingAccountDialog(false)}
        onConfirm={(hasRevolutAccount) => {
          if (hasRevolutAccount) {
            goToNextStep()
          } else {
            const a = document.createElement('a')
            a.setAttribute('href', REVOLUT_BUSINESS_SIGNUP_URL)
            a.setAttribute('target', '_blank')
            a.click()
          }
          setShouldShowExistingAccountDialog(false)
        }}
      />
    )}
  </>)
}

const Signup = ({ companyWallet }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.card} variant="outlined" >
      <CardContent>
        <Introduction goToNextStep={() => history.push(
          ROUTE_URL.tlpayRevolutSignup.replace(':companyId', companyWallet.companyId)
        )} />
      </CardContent>
    </Card>
  )
}

Signup.propTypes = {
  companyWallet: PropTypes.object,
}

export default connect(Signup)
