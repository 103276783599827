import { connect } from 'react-redux'
import Component from './UsersInvites'
import { onNavigationChange } from "store/routes"

export const mapState = ({ auth }) => ({
  auth,
})

export const mapDispatch = (dispatch) => ({
  push: (data) => dispatch(onNavigationChange(data)),
})

export default connect(mapState, mapDispatch)(Component)
