import React from 'react';
import { Box, Typography } from '@material-ui/core';

function RevolutWithdrawal() {
  return (
    // FIXME: Update the content when the instruction texts are confirmed
    <Box>
      <Typography variant="h5" gutterBottom>
        Revolut Withdrawal Instructions
      </Typography>
      <Box mt={2}>
        Gubergren et lorem no et dolore lorem. Tempor eirmod dolore ut erat duo eirmod et, et amet invidunt sea clita, dolores at sit invidunt est clita stet vero, voluptua amet eos ut ut, labore sanctus tempor ipsum justo justo magna. Et et nonumy takimata sea. Est sed invidunt no aliquyam, aliquyam ipsum ipsum no diam eirmod sanctus at, ipsum no gubergren dolore erat lorem. Duo tempor dolore sadipscing dolore labore dolore at eirmod nonumy. Sit sanctus gubergren est gubergren, et.
      </Box>
    </Box>
  )
}

export default RevolutWithdrawal;
