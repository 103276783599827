import React, { useMemo, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useQuery } from 'react-query'
import { get, post } from 'utils/api'
import { API_URL, EWALLET_PROVIDER, EWALLET_STATUS, ROUTE_URL } from 'constants.js'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import AuthView from 'containers/AuthView/connect'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, Card, CardContent, Grid, Step, StepLabel, Stepper } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { Redirect, useParams } from 'react-router'
import { getCompanyText } from './utils'
import { useStyles } from './styles'
import { useHistory } from 'react-router'
import TermsAndConditions from './components/revolut/TermsAndConditions'
import ConfigureRevolutSettings from './components/revolut/ConfigureRevolutSettings'
import ConfigureClientId from './components/revolut/ConfigureClientId'
import EnableRevolutAccess from './components/revolut/EnableRevolutAccess'

function TLPayRevolutSignupPage({
  location,
  auth,
  currUserCompany,
}) {
  const history = useHistory()
  const urlParams = useParams()
  const classes = useStyles()
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const { formatMessage } = useIntl()
  const [companyWallet, setCompanyWallet] = useState()
  const [selectedOption, setSelectedOption] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [signupId, setSignupId] = useState('')
  const [clientId, setClientId] = useState('')
  const steps = useMemo(() => [
    {
      labelKey: formatMessage({ id: 'tlpay.copy-certificate-and-uri' }),
      titleKey: formatMessage({ id: 'tlpay.copy-certificate-redirect-uri' }),
    },
    {
      labelKey: formatMessage({ id: 'tlpay.paste-client-id' }),
      titleKey: formatMessage({ id: 'tlpay.paste-client-id' }),
    },
    {
      labelKey: formatMessage({ id: 'tlpay.enable-access' }),
      titleKey: formatMessage({ id: 'tlpay.enable-access' }),
    },
  ], [formatMessage])
  const {
    data: wallets,
    isLoading: isLoadingWallets,
  } = useQuery('getAllWallets', () => get(`${API_URL}/e-wallet/wallets`), { retry: false })
  const {
    data: signup,
    isLoading: isLoadingSignup,
  } = useQuery(
    `createSignupIfNeeded/${companyWallet?.companyId}`,
    () => post(`${API_URL}/e-wallet/revolut-onboard`, { companyId: companyWallet.companyId }),
    { enabled: !!companyWallet?.companyId, retry: false },
  )

  const eligibleCompanies = useMemo(() => {
    if (isLoadingWallets || !wallets?.length) return []
    if (history.location?.state?.from === ROUTE_URL.tlpayRevolutSignupComplete) {
      return wallets
    }
    return wallets
      .filter(w =>
        !w.wallets.find(ww => ww.status === EWALLET_STATUS.ACTIVE && [EWALLET_PROVIDER.REVOLUT].includes(ww.provider))
      )
  }, [wallets, isLoadingWallets, history])

  const companyOptions = useMemo(() => eligibleCompanies.map(w => ({
    id: w.companyId,
    label: getCompanyText(w),
  })), [eligibleCompanies])

  const showSelect = !isLoadingWallets && companyOptions.length > 1

  const handleNextStep = () => setActiveStep(s => Math.min(steps.length, s + 1))
  const handlePrevStep = () => {
    if (activeStep === 0) {
      history.push(ROUTE_URL.tlpayOpenNewAccount)
    } else {
      setActiveStep(s => Math.max(0, s - 1))
    }
  }
  const handleSignupComplete = async () => history.push(ROUTE_URL.tlpayAccounts)

  useEffect(() => {
    if (signupId) {
      (async () => {
        await post(`${API_URL}/e-wallet/revolut-onboard/${encodeURIComponent(signupId)}`, {
          step: activeStep,
        })
      })()
    }
  }, [activeStep, signupId])

  useEffect(() => {
    if (!isLoadingSignup && signup) {
      setActiveStep(
        signup?.data?.step
          ? Math.max(0, Math.min(steps.length, signup.data.step))
          : 0
      )
      setSignupId(signup?.id ?? '')
      setClientId(signup?.data?.clientId ?? '')
    }
  }, [signup, isLoadingSignup, steps, setActiveStep, setSignupId, setClientId])

  useEffect(() => {
    setGlobalSpinner(isLoadingSignup || isLoadingWallets)
  }, [isLoadingWallets, isLoadingSignup, setGlobalSpinner])

  useEffect(() => {
    // auto select
    const preselected = eligibleCompanies?.find(w => w.companyId === urlParams.companyId)
    setCompanyWallet(preselected)

    // manage autocomplete value
    const optionToSelect = companyOptions.find(co => co.id === preselected?.companyId)
    setSelectedOption(optionToSelect)
  }, [
    companyOptions,
    eligibleCompanies,
    currUserCompany?.id,
    companyWallet?.companyId,
    urlParams.companyId,
  ])

  if (isLoadingWallets) {
    return null
  }

  if (!eligibleCompanies.length) {
    return (<Redirect to={ROUTE_URL.tlpayAccounts} />)
  }

  if (!urlParams.companyId || !eligibleCompanies.find(c => c.companyId === urlParams.companyId)) {
    return <Redirect to={ROUTE_URL.tlpayOpenNewAccount} />
  }

  return (
    <AuthView location={location} auth={auth} title="TL Pay">
      <Grid style={{ marginBottom: '10px' }} container xs={12} alignItems='center'>
        <Typography variant='h1' gutterBottom>
          {formatMessage({ id: 'tlpay.tl-pay-signup' })} - {formatMessage({ id: 'tlpay.revolut' })}
        </Typography>

        {showSelect && <Autocomplete
          disabled
          options={companyOptions}
          variant="outlined"
          fullWidth
          dense
          name="select-company"
          value={selectedOption}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label={formatMessage({ id: 'tlpay.my-tl-pay-accounts' })} variant="outlined" />
          )}
        ></Autocomplete>}
      </Grid>

      <Box mx={2} my={4} variant="">
        <Box my={4}>
          <Typography variant="h2" color="primary">
            {formatMessage({ id: 'tlpay.link-your-revolut-account' })}
          </Typography>
        </Box>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            {activeStep === 0 && (
              <TermsAndConditions
                onComplete={handleNextStep}
              />
            )}
            {activeStep > 0 && (<>
              <div>
                <Stepper activeStep={activeStep - 1} alternativeLabel>
                  {steps.map(({ labelKey }) => (
                    <Step key={labelKey}>
                      <StepLabel>{formatMessage({ id: labelKey })}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>

              <Box mt={4}>
                <Typography variant="h3">
                  {formatMessage({ id: 'tlpay.step' })} {activeStep}:&nbsp;
                  {formatMessage({ id: steps[activeStep - 1]?.titleKey })}
                </Typography>
                <Box my={4}>
                  {activeStep === 1 && (
                    <ConfigureRevolutSettings
                      signupId={signupId}
                      companyId={selectedOption?.id}
                      onComplete={handleNextStep}
                      onCancel={handlePrevStep}
                      onSignupIdChange={id => setSignupId(id)}
                      onClientIdChange={id => setClientId(id)}
                    />
                  )}

                  {activeStep === 2 && (
                    <ConfigureClientId
                      clientId={clientId}
                      signupId={signupId}
                      onComplete={handleNextStep}
                      onCancel={handlePrevStep}
                      onClientIdChange={id => setClientId(id)}
                    />
                  )}

                  {activeStep === 3 && (
                    <EnableRevolutAccess
                      signupId={signupId}
                      onComplete={handleSignupComplete}
                      onCancel={handlePrevStep}
                    />
                  )}
                </Box>
              </Box>
            </>)}
          </CardContent>
        </Card>
      </Box>
    </AuthView>
  )
}

TLPayRevolutSignupPage.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(TLPayRevolutSignupPage)
