export const PAYMENT_METHOD_FILTER_OPTIONS = {
  ALL: 'All',
  DIRECT_DEBIT_UK: 'Direct Debit',
  E_WALLET: 'E-Wallet',
}

export const STATUS_FILTER_OPTIONS = {
  ALL: 'All',
  COMPLETED: 'Completed',
  COLLECTION_CLEARED: 'Collection Cleared',
  ESTIMATED: 'Estimated',
}

export const initialFilterValues = {
  paymentMethod: 'ALL',
  status: 'ALL',
  type: 'ALL',
}

export const COMPANY_SETTLEMENT_SUMMARIES_HIDDEN_COLUMNS = [
  'collectionAt'
]

export const COMMERCIAL_LINK_NETS_HIDDEN_COLUMNS = [
  'remittanceRef'
]