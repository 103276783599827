import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL } from 'constants.js'
import { useStyles } from '../../styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import { useIntl } from 'react-intl'

const titleValueComponent = (title, value) => (
  <Grid container spacing={2}>
    <Grid item xs={2}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
    </Grid>
    <Grid item>
      <Typography gutterBottom>
        {value}
      </Typography>
    </Grid>
  </Grid>
)

const RevolutFunding = ({ accountSelected, companyWallet }) => {
  // FIXME: This component is just copied from Funding.js. Update this component when the design is ready.
  const classes = useStyles()
  const { companyId } = companyWallet || {}
  const companyWalletId = accountSelected?.companyWalletId
  const { data, refetch } = useQuery(
    `details/${companyId}/${companyWalletId}`,
    () => get(`${API_URL}/e-wallet/details/${encodeURIComponent(companyId)}/${encodeURIComponent(companyWalletId)}`, { currency: accountSelected.currency }),
    { retry: false },
  )
  const { formatMessage } = useIntl()

  useEffect(() => {
    refetch()
  }, [accountSelected, refetch])

  const accounts = data || []
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {formatMessage({ id: 'funding.bank-account-details-for-funding' })}
      </Typography>
      <Typography gutterBottom>
      {formatMessage({ id: 'funding.to-fund-your-tl-pay-account-please-use-the-bank-account-details-listed-below-for-both-your-initial-deposit-and-all-future-transactions-please-note' })}
      <ul>
        <li>
          {formatMessage({ id: 'funding.the-bank-accounts-used-for-deposits-must-have-the-same-company-name-as-the-one-registered-in-travel-ledger-sending-funds-from-an-account-with-a-different-company-name-will-result-in-rejection-and-return-of-funds' })}
        </li>
        <li style={{ marginTop: '10px' }}>
          {formatMessage({ id: 'funding.any-deposit-from-a-new-bank-account-will-automatically-be-added-to-your-whitelist-of-accounts-available-for-withdrawals' })}
        </li>
      </ul>
      </Typography>
      {accounts.map(account =>
        <div key={account.id}>
          <Card className={classes.card} variant="outlined" >
            <List dense>
              <ListItem className={classes.listItem}>
                {titleValueComponent(formatMessage({ id: 'funding.account-name' }), account?.name)}
              </ListItem>
              <ListItem className={classes.listItem}>
                {titleValueComponent(formatMessage({ id: 'funding.iban' }), account?.iban)}
              </ListItem>
              {!!account?.sortCode && (
                <ListItem className={classes.listItem}>
                  {titleValueComponent(formatMessage({ id: 'funding.account-number' }), account?.iban.slice(-8))}
                </ListItem>
              )}
              <ListItem className={classes.listItem}>
                {titleValueComponent(
                  !!account?.sortCode
                    ? formatMessage({ id: 'funding.sort-code' })
                    : 'Routing Number',
                  account?.routingNumber,
                )}
              </ListItem>
              <ListItem className={classes.listItem}>
                {titleValueComponent(formatMessage({ id: 'funding.bank-name' }), account?.bankName || account?.bankCountry)}
              </ListItem>
              <ListItem className={classes.listItem}>
                {titleValueComponent(formatMessage({ id: 'funding.currency' }), account?.currency)}
              </ListItem>
            </List>
          </Card>
        </div>
      )}
    </>
  )
}

RevolutFunding.propTypes = {
  accountSelected: PropTypes.object,
  companyWallet: PropTypes.object,
}

export default RevolutFunding
