import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(24)
  },
  balanceCard: {
    boxSizing: 'border-box',
    position: 'relative',
    opacity: .5,

    '& .MuiCardContent-root': {
      position: 'relative',
    },

    '&:not($cardSelected) .MuiCardContent-root': {
      marginTop: 3,
    },

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      backgroundColor: theme.palette.action.disabledBackground,
      pointerEvents: 'none',
    },

    '&$cardSelected:after': {
      display: 'none',
    }
  },
  cardSelected: {
    opacity: 1,
    borderTop: `3px ${theme.palette.primary.light} solid !important`
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  fullWidth: {
    flexGrow: 1,
  },
  gridRow: {
    marginTop: theme.spacing(2),
  },
  accountList: {
    "&>*": {
      borderBottom: `1px ${theme.palette.divider} solid`,
      borderLeft: `1px ${theme.palette.divider} solid`,
      borderRight: `1px ${theme.palette.divider} solid`,
    },
    "&>*:nth-child(1)": {
      border: 'none',
    },
    "&>*:nth-child(2)": {
      borderTop: `1px ${theme.palette.divider} solid`,
    },
  },
  withdrawal: {
    paddingTop: theme.spacing(1.5)
  },
  gridPlaceholder: {
    minHeight: theme.spacing(3)
  },
  providerIconContainer: {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    maxWidth: '5rem',
    maxHeight: '3rem',
  },
}))
