import React, { useMemo } from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './styles'

import { API_URL, EWALLET_PROVIDER, EWALLET_STATUS, isProduction, ROUTE_URL, SUBSCRIPTION_TYPES } from 'constants.js'
import NavigationDrawerItem from 'components/NavigationDrawerItem'
import PropTypes from 'prop-types'
import NavigationDrawerSection from '../NavigationDrawerSection'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import logoWhite from 'assets/logo_white.png'
import ListItem from '@material-ui/core/ListItem'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { StylesProvider } from '@material-ui/core/styles'
import { LANGUAGE_CULTURE_NAMES } from '@travel-ledger/type-constants'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { getRegionAndProvider } from 'containers/TLPay/utils'

const SECTIONS = {
  DATA: 'd',
  PARTNERS: 'p',
  SETTINGS: 's',
  PAYMENTS: 'py',
  BILLING: 'b',
  TLPAY: 't',
}

const LANGUAGE_OPTIONS = [
  'en-GB',
  'it-IT',
].filter(l => LANGUAGE_CULTURE_NAMES[l])

const NavigationDrawer = ({
  children,
  invisible,
  isProcessingEntity,
  push,
  logout,
  auth,
  company,
  language,
  onLanguageSettingsChange,
}) => {
  const isHeadOffice = Boolean(company.isHeadOffice)
  const isSubscriptionBasic = !company.subscription?.type || company.subscription?.type === SUBSCRIPTION_TYPES.BASIC
  const [section, setSection] = React.useState(null)
  const classes = useStyles({ isProduction })
  const { formatMessage } = useIntl()
  const { isAdmin } = auth
  const walletEnabled = !!auth?.id && isAdmin
  const {
    data: wallets,
    isLoading: isLoadingWallets,
  } = useQuery('getAllWallets', () => get(`${API_URL}/e-wallet/wallets`), {
    retry: false,
    enabled: walletEnabled,
  })

  const hasActiveWallet = useMemo(() => {
    if (!walletEnabled || isLoadingWallets) return false
    const ws = wallets?.flatMap(w => w.wallets ?? [])?.filter(w => w)
    return !!ws?.length && ws.some(w => w?.status === EWALLET_STATUS.ACTIVE)
  }, [wallets, isLoadingWallets, walletEnabled])

  const isEligibleToOpenAccount = useMemo(() => {
    if (!walletEnabled || isLoadingWallets) return false
    return wallets?.filter(w =>
      !w.wallets.find(ww => ww.status === EWALLET_STATUS.ACTIVE && ww.provider === EWALLET_PROVIDER.REVOLUT) || (
        getRegionAndProvider(w.countryAlpha2)?.provider &&
        !w.wallets.find(ww => ww.status === EWALLET_STATUS.ACTIVE && [EWALLET_PROVIDER.NIUM_EU, EWALLET_PROVIDER.NIUM_UK].includes(ww.provider))
      )
    )
  }, [wallets, isLoadingWallets, walletEnabled])

  const sectionItemProps = {
    activeSection: section,
    setActiveSection: setSection
  }

  const handleLogoutClick = () => {
    logout()
    push(ROUTE_URL.home)
  }

  const handleLanguageSettingsChange = async (e) => {
    onLanguageSettingsChange(e.target.value)
  }

  return (
    <StylesProvider injectFirst>
      <div className={classes.root}>
        {!invisible && (
          <Drawer
            variant="permanent"
            className={`${classes.drawer} ${classes.drawerOpen}`}
            classes={{ paper: classes.drawerOpen }}
            open={true}
          >
            <List className={classes.list}>
              <ListItem className={classes.logoContainer}>
                <img
                  src={logoWhite}
                  className={classes.logo}
                  alt="logo"
                  onClick={() => push(ROUTE_URL.home)}
                />
                {process.env.REACT_APP_VERSION && <span className={classes.version}>v{process.env.REACT_APP_VERSION}</span>}
              </ListItem>
              {false && ( // {!isProcessingEntity && (
                <NavigationDrawerItem
                  title={formatMessage({ id: 'nav-drawer.dashboard' })}
                  onClick={() => push(ROUTE_URL.home)}
                  className={classes.drawerItemHome}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.home]}
                />
              )}
              <Divider />
              <NavigationDrawerItem
                title={formatMessage({ id: 'nav-drawer.transactions' })}
                onClick={() => push(ROUTE_URL.bookingsReview)}
                classNameActive={classes.drawerItemHomeActive}
                routes={[ROUTE_URL.bookingsReview, ROUTE_URL.remittancesReview, ROUTE_URL.csv]}
              />
              {walletEnabled && <>
                <Divider />
                <NavigationDrawerSection
                  title={formatMessage({ id: 'nav-drawer.tl-pay' })}
                  sectionName={SECTIONS.TLPAY}
                  {...sectionItemProps}
                >
                  {hasActiveWallet && (
                    <NavigationDrawerItem
                      isSection
                      title={formatMessage({ id: 'nav-drawer.tl-pay-my-account' })}
                      titleClasses={classes.section}
                      onClick={() => push(ROUTE_URL.tlpayAccounts)}
                      classNameActive={classes.drawerItemHomeActive}
                      routes={[ROUTE_URL.tlpayAccounts]}
                    />
                  )}
                  {isEligibleToOpenAccount && (
                    <NavigationDrawerItem
                      isSection
                      title={formatMessage({ id: 'nav-drawer.tl-pay-open-new-account' })}
                      titleClasses={classes.section}
                      onClick={() => push(ROUTE_URL.tlpayOpenNewAccount)}
                      classNameActive={classes.drawerItemHomeActive}
                      routes={[ROUTE_URL.tlpayOpenNewAccount]}
                    />
                  )}
                </NavigationDrawerSection>
              </>}
              <Divider />
              <NavigationDrawerSection
                title={formatMessage({ id: 'nav-drawer.partners' })}
                sectionName={SECTIONS.PARTNERS}
                {...sectionItemProps}
              >
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.commercial-links' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.commercialLinksList)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.commercialLinksList]}
                />
                <Divider />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.business-terms' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.businessTermsList)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.businessTermsList]}
                />
                <Divider />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.invite-partners' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.companyInvite)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.companyInvite]}
                />
                <Divider />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.company-list' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.companiesList)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.companiesList]}
                />
              </NavigationDrawerSection>
              <Divider />
              <NavigationDrawerItem
                title={formatMessage({ id: 'nav-drawer.notifications' })}
                onClick={() => push(ROUTE_URL.notifications)}
                classNameActive={classes.drawerItemHomeActive}
                routes={[ROUTE_URL.notifications]}
                {...auth.unreadNotifications && { badgeContent: auth.unreadNotifications }}
                {...sectionItemProps}
              />
              <Divider />
              <NavigationDrawerSection
                title={formatMessage({ id: 'nav-drawer.payments' })}
                sectionName={SECTIONS.PAYMENTS}
                {...sectionItemProps}
              >
                <Divider />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.payment-profiles' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.paymentProfileList)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.paymentProfileList]}
                />
                {isProcessingEntity && !isProduction && (
                  <>
                    <Divider />
                    <NavigationDrawerItem
                      isSection
                      title={formatMessage({ id: 'nav-drawer.simulation-center' })}
                      titleClasses={classes.section}
                      onClick={() => push(ROUTE_URL.simulationTool)}
                      classNameActive={classes.drawerItemHomeActive}
                      routes={[ROUTE_URL.simulationTool]}
                    />
                  </>
                )}
                <Divider />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.settlement-schedules' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.settlementSchedulesList)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.settlementSchedulesList]}
                />
              </NavigationDrawerSection>
              <Divider />
              <NavigationDrawerSection
                title={formatMessage({ id: 'nav-drawer.settings' })}
                sectionName={SECTIONS.SETTINGS}
                {...sectionItemProps}
              >
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.user-profile' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.userProfile)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.userProfile]}
                />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.companies-and-users' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.entityProfile)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.entityProfile]}
                />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.notifications-configuration' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.notificationsConfiguration)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.notificationsConfiguration]}
                />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.data-delivery' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.fileDataDelivery)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.fileDataDelivery]}
                />
                <NavigationDrawerItem
                  isSection
                  title={formatMessage({ id: 'nav-drawer.task-scheduler' })}
                  titleClasses={classes.section}
                  onClick={() => push(ROUTE_URL.taskScheduler)}
                  classNameActive={classes.drawerItemHomeActive}
                  routes={[ROUTE_URL.taskScheduler]}
                />
              </NavigationDrawerSection>
              {isHeadOffice && (<>
                <Divider />
                <NavigationDrawerSection
                  title={formatMessage({ id: 'nav-drawer.billing' })}
                  sectionName={SECTIONS.BILLING}
                  {...sectionItemProps}
                >
                  <Divider />
                  <NavigationDrawerItem
                    isSection
                    title={formatMessage({ id: 'nav-drawer.subscription' })}
                    titleClasses={classes.section}
                    onClick={() => push(ROUTE_URL.billingSubscription)}
                    classNameActive={classes.drawerItemHomeActive}
                    routes={[ROUTE_URL.billingSubscription]}
                  />
                  <Divider />
                  {!isSubscriptionBasic &&
                    <NavigationDrawerItem
                      isSection
                      title={formatMessage({ id: 'nav-drawer.invoices' })}
                      titleClasses={classes.section}
                      onClick={() => push(ROUTE_URL.billingInvoices)}
                      classNameActive={classes.drawerItemHomeActive}
                      routes={[ROUTE_URL.billingInvoices]}
                    />
                  }
                  <Divider />
                  <NavigationDrawerItem
                    isSection
                    title={formatMessage({ id: 'nav-drawer.billing-details' })}
                    titleClasses={classes.section}
                    onClick={() => push(ROUTE_URL.billingDetails)}
                    classNameActive={classes.drawerItemHomeActive}
                    routes={[ROUTE_URL.billingDetails]}
                  />
                  {/* <Divider />
                  <NavigationDrawerItem title={'Payment method'} onClick={() => push(ROUTE_URL.billingPaymentMethod)} /> */}
                </NavigationDrawerSection>
              </>)}
              <Divider />
              <NavigationDrawerItem
                title={formatMessage({ id: 'nav-drawer.help' })}
                onClick={() => window.open('https://help.travelledger.org/', '_blank')}
                className={classes.drawerItemHome}
              />
              <Divider />
            </List>
            <List>
              <NavigationDrawerItem
                button={false}
                title={auth.name}
                titleClasses={classes.authName}
                subtitle={company.name}
                subtitleClasses={classes.companyName}
              />
              {!!LANGUAGE_OPTIONS.length && (
                <ListItem>
                  <Select
                    value={language}
                    onChange={handleLanguageSettingsChange}
                    fullWidth
                  >
                    {LANGUAGE_OPTIONS.map(lang => (
                      <MenuItem key={lang} value={lang}>
                        {LANGUAGE_CULTURE_NAMES[lang]}
                      </MenuItem>
                    ))}
                  </Select>
                </ListItem>
              )}
              <NavigationDrawerItem
                title={formatMessage({ id: 'nav-drawer.logout' })}
                Icon={PowerSettingsNewIcon}
                onClick={handleLogoutClick}
                className={[classes.drawerItemHome, classes.logout]}
              />
            </List>
          </Drawer>
        )}
        <main className={classes.content}>
          {children()}
        </main>
      </div>
    </StylesProvider>
  )
}

NavigationDrawer.propTypes = {
  invisible: PropTypes.bool,
  isProcessingEntity: PropTypes.bool,
  children: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object
}

export default NavigationDrawer
