import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-query'
import { get, patch, post, del } from 'utils/api'
import { API_URL, CURRENCY, SCHEDULE_DAY_ABBREVIATION_KEY_TITLE } from 'constants.js'
import { useStyles } from '../styles'
import { InputText } from 'components/ui/Form/InputText'
import { useForm, useWatch } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import { formSchema, defaultValues, MAX_REFERENCE_MSG_LEN, FREQUENCY_TYPE_KEY_TITLE, DAYS_OF_MONTH_KEY_TITLE } from '../withdrawalValidationSchema'
import Button from 'components/Button'
import { getReadableErrorMessage } from 'utils/errors'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Radio from '@material-ui/core/Radio'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import ConfirmDialog from 'components/ConfirmDialog'
import MFACodeAuthorizeDialogForm from 'components/MFACodeAuthorizeDialogForm/MFACodeAuthorizeDialogForm'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { Alert } from '@material-ui/lab'
import DataGrid from 'components/DataGrid'
import ScheduledWithdrawalDialog from './ScheduledWithdrawalDialog'
import { useIntl } from 'react-intl'
import { RefreshButton } from 'components/DataGrid/components/ActionButtons'
import { kebabCase } from 'lodash'
import { ScheduledWithdrawalFrequencyType } from '@travel-ledger/type-constants'

const MAX_SCHEDULED_WITHDRAWALS = 1

const Withdrawal = ({ accountSelected, handleSetTabSelected, companyWallet }) => {
  const classes = useStyles()
  const { companyId } = companyWallet || {}
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [whitelistedAccountToBeDeleted, setWhitelistedAccountToBeDeleted] = useState(null)
  const [mfaOpen, setMfaOpen] = useState(false)
  const [mfaCallback, setMfaCallback] = useState(null)
  const [shouldScheduledWithdrawalDialogOpen, setShouldScheduledWithdrawalDialogOpen] = useState(false)
  const [shouldScheduledWithdrawalDeleteDialogOpen, setShouldScheduledWithdrawalDeleteDialogOpen] = useState(false)
  const scheduledWithdrawalBeingEditedRef = React.useRef(null)
  const scheduledWithdrawalBeingDeletedRef = React.useRef(null)
  const tryAgainAtRef = React.useRef(null)
  const { formatMessage } = useIntl()

  const { data: balanceData, refetch: refetchBalanceData } = 
    useQuery(
      'available-balance', 
      () => get(`${API_URL}/e-wallet/balance/available/${companyId}/?currency=${accountSelected.currency}`), 
      {
        retry: false
      })
  const { data: whitelistData, refetch: refetchWhitelist, isFetching: isFetchingWhitelist } = 
    useQuery(
      'whitelist',
      () => get(`${API_URL}/e-wallet/whitelist/${companyId}`), 
      {
        retry: false
      })
  const { data: scheduledWithdrawalData, refetch: refetchScheduledWithdrawals } = 
    useQuery(
      'scheduledWithdrawals',
      () => get(`${API_URL}/e-wallet/scheduled-withdrawals/${encodeURIComponent(companyId)}`), 
      {
        retry: false
      })
  const scheduledWithdrawalLookup = useMemo(() => new Map(
    scheduledWithdrawalData?.data?.map(row => [row.id, row])
  ), [scheduledWithdrawalData])

  const { 
    register, 
    setValue, 
    handleSubmit, 
    formState: { errors }, 
    control
  } = useForm({
    resolver: joiResolver(formSchema(balanceData?.availableBalance, accountSelected?.currency)),
    defaultValues
  })

  const scheduledWithdrawals = useMemo(() => {
    if (!Array.isArray(scheduledWithdrawalData?.data)) return []
    if (!Array.isArray(whitelistData)) return []

    const accountLookup = new Map(whitelistData.map(w => [w.id, w]))

    return scheduledWithdrawalData.data
      .filter(row => row.currency === accountSelected?.currency)
      .map(row => {
        const account = accountLookup.get(row.account)?.accountNumber
        const time = row.configuration.timeOfDay
        const reference = row.configuration.reference
        let amount = row.configuration.currency + ' ' + row.configuration.amount

        if (row.configuration.withdrawAllAvailableFunds) {
          amount = formatMessage({ id: 'withdrawal-tab.all' })
        } else if (row.configuration.minAmountLeftEnabled) {
          amount = formatMessage({ id: 'withdrawal-tab.leave' }) + ' ' +
            row.configuration.currency + ' ' + row.configuration.minAmountLeft
        }

        const freq = row.configuration.frequencyType
        let when = formatMessage({ id: `withdrawal-tab.${kebabCase(FREQUENCY_TYPE_KEY_TITLE[freq])}` })

        if (freq === ScheduledWithdrawalFrequencyType.WEEKLY) {
          when += '; ' + row.configuration.daysOfWeek
            .map(d => formatMessage({ id: `withdrawal-tab.${kebabCase(SCHEDULE_DAY_ABBREVIATION_KEY_TITLE[d])}` }))
            .join(', ')
        } else if (freq === ScheduledWithdrawalFrequencyType.MONTHLY) {
          when += '; ' + row.configuration.daysOfMonth
            .map(d => typeof d === 'number' ? d : formatMessage({ id: `withdrawal-tab.${kebabCase(DAYS_OF_MONTH_KEY_TITLE[d])}` }))
            .join(', ')
        }

        if (!account) return null
        return {
          id: row.id,
          account,
          time,
          reference,
          amount,
          when,
        }
      })
      .filter(x => x)
  }, [accountSelected, whitelistData, scheduledWithdrawalData, formatMessage])

  useEffect(() => {
    refetchBalanceData()
    refetchWhitelist()
    refetchScheduledWithdrawals()
  }, [accountSelected, refetchBalanceData, refetchWhitelist, refetchScheduledWithdrawals])
  

  const createWithdrawal = (withdrawal) => {
    return post(`/e-wallet/withdrawal/${companyId}`, {
      ...withdrawal,
      currency: accountSelected.currency
    })
  }

  const createScheduledWithdrawal = (withdrawal) => {
    return post(`/e-wallet/scheduled-withdrawals/${encodeURIComponent(companyId)}`, {
      ...withdrawal,
      currency: accountSelected.currency,
    })
  }

  const editScheduledWithdrawal = ({ id, ...withdrawal }) => {
    return patch(`/e-wallet/scheduled-withdrawals/${encodeURIComponent(companyId)}/${id}`, {
      ...withdrawal,
      currency: accountSelected.currency,
    })
  }

  const deleteScheduledWithdrawal = ({ id, otpToken }) => {
    return del(`/e-wallet/scheduled-withdrawals/${encodeURIComponent(companyId)}/${id}`, {
      headers: {
        'tl-otp-token': otpToken,
      },
    })
  }

  const { mutate: createWithdrawalMutation, isLoading: isCreating } = useMutation(createWithdrawal, {
    onSuccess: () => {
      Object.assign(tryAgainAtRef, { current: null })
      toast.success(formatMessage({ id: 'withdrawal-tab.withdrawal-created-successfully' }))
      handleSetTabSelected(0)
    },
    onError: (error) => {
      Object.assign(tryAgainAtRef, {
        current: error?.headers['x-retry-at']
      })
      toast.error(getReadableErrorMessage(error))
    },
  })

  const { mutate: createScheduledWithdrawalMutation, isLoading: isCreatingSchedule } = useMutation(createScheduledWithdrawal, {
    onSuccess: () => {
      Object.assign(tryAgainAtRef, { current: null })
      toast.success(formatMessage({ id: 'withdrawal-tab.withdrawal-scheduled-successfully' }))
      setShouldScheduledWithdrawalDialogOpen(false)
      Object.assign(scheduledWithdrawalBeingEditedRef, { current: null })
      refetchScheduledWithdrawals()
    },
    onError: (error) => {
      Object.assign(tryAgainAtRef, {
        current: error?.headers['x-retry-at']
      })
      toast.error(getReadableErrorMessage(error))
    },
  })

  const { mutate: editScheduledWithdrawalMutation } = useMutation(editScheduledWithdrawal, {
    onSuccess: () => {
      Object.assign(tryAgainAtRef, { current: null })
      toast.success(formatMessage({ id: 'withdrawal-tab.edit-scheduled-withdrawal-successfully' }))
      setShouldScheduledWithdrawalDialogOpen(false)
      Object.assign(scheduledWithdrawalBeingEditedRef, { current: null })
      refetchScheduledWithdrawals()
    },
    onError: (error) => {
      Object.assign(tryAgainAtRef, {
        current: error?.headers['x-retry-at']
      })
      toast.error(getReadableErrorMessage(error))
    },
  })

  const { mutate: deleteScheduledWithdrawalMutation } = useMutation(deleteScheduledWithdrawal, {
    onSuccess: () => {
      Object.assign(tryAgainAtRef, { current: null })
      toast.success(formatMessage({ id: 'withdrawal-tab.delete-scheduled-withdrawal-successfully' }))
      setShouldScheduledWithdrawalDeleteDialogOpen(false)
      Object.assign(scheduledWithdrawalBeingDeletedRef, { current: null })
      refetchScheduledWithdrawals()
    },
    onError: (error) => {
      Object.assign(tryAgainAtRef, {
        current: error?.headers['x-retry-at']
      })
      toast.error(getReadableErrorMessage(error))
    },
  })

  const deleteWhitelistedAccount = (id) => {
    // eslint-disable-next-line no-param-reassign
    return del(`/e-wallet/whitelist/${companyId}/${id}`)
  }

  const { mutate: deleteWhitelistedAccountMutation } = useMutation(deleteWhitelistedAccount, {
    onSuccess: () => {
      toast.success(formatMessage({ id: 'withdrawal-tab.whitelisted-account-deleted-successfully' }))
      setMfaOpen(false)
      refetchWhitelist()
    },
    onError: (error) => {
      toast.error(getReadableErrorMessage(error))
    },
  })

  const onSubmit = handleSubmit((formData) => {
    setMfaOpen(true)
    setMfaCallback(() => (otpToken) =>
      createWithdrawalMutation({ ...formData, otpToken })
    )
  })

  const handleWithdrawalSchedule = (formData) => {
    setMfaOpen(true)
    setMfaCallback(() => (otpToken) => {
      const payload = { ...formData, otpToken }
      if (payload.amount === '') {
        delete payload.amount
      }
      if (payload.minAvailableFunds === '') {
        delete payload.minAvailableFunds
      }
      if (payload.minAmountLeft === '') {
        delete payload.minAmountLeft
      }
      createScheduledWithdrawalMutation({ ...formData, otpToken })
      setMfaOpen(false)
    })
  }

  const handleScheduledWithdrawalEdit = (formData) => {
    setMfaOpen(true)
    setMfaCallback(() => (otpToken) => {
      const payload = { ...formData, otpToken }
      if (payload.amount === '') {
        delete payload.amount
      }
      if (payload.minAvailableFunds === '') {
        delete payload.minAvailableFunds
      }
      if (payload.minAmountLeft === '') {
        delete payload.minAmountLeft
      }
      editScheduledWithdrawalMutation(payload)
      setMfaOpen(false)
    })
  }

  const handleScheduledWithdrawalDelete = () => {
    setMfaOpen(true)
    setMfaCallback(() => (otpToken) => {
      deleteScheduledWithdrawalMutation({
        id: scheduledWithdrawalBeingDeletedRef.current?.id,
        otpToken,
      })
      setMfaOpen(false)
    })
  }

  const handleScheduledWithdrawalDialogClose = () => {
    setShouldScheduledWithdrawalDialogOpen(false)
    Object.assign(scheduledWithdrawalBeingEditedRef, { current: null })
  }

  const whitelist = whitelistData?.filter(beneficiary => beneficiary.currency === accountSelected.currency) || []
  const whitelistWarning = !whitelist.length && !isFetchingWhitelist
  const selectedWhitelistAccount = useWatch({ control, name: 'account' })
  const limitMessage = accountSelected.currency === CURRENCY.GBP && (
    <span>
      <br /><br />
      {formatMessage({ id: 'withdrawal.uk-local-payout' })}:<br /><br />
      {formatMessage({ id: 'withdrawal.for-amounts-1-m-fps-is-used-and-the-payments-are-in-real-time' })}<br /><br />
      {formatMessage({ id: 'withdrawal.for-amounts-1-m-chaps-is-used-and-the-payments-are-processed-the-same-day-subject-to-15-30-gmt-cut-off' })}
    </span>
  )
  const infoMessage = <span>{formatMessage({ id: 'withdrawal.your-available-balance-for-withdrawal-is-minus-any-funds-transferred-from-another-wallet-for-the-first-24-hours-after-transfer-only' })} {limitMessage} </span>

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <List className={classes.accountList} subheader={<ListSubheader>{formatMessage({ id: 'withdrawal.to-whitelisted-account' })}:</ListSubheader>}>
            {whitelistWarning && <Alert severity='warning'>
              {formatMessage({ id: 'withdrawal.oops-it-seems-you-don-t-have-a-whitelisted-bank-account-for-withdrawals' })} <br /> <br />
              {formatMessage({ id: 'withdrawal.to-enable-withdrawals-visit-the-funding-tab-to-find-instructions-and-make-a-small-initial-deposit-in-your-default-currency-5-00' })} <br /> <br />
              {formatMessage({ id: 'withdrawal.the-bank-account-used-for-this-initial-deposit-will-become-a-designated-withdrawal-account' })}
            </Alert>}

            {whitelist.map((beneficiary, index) => {
              return (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Radio
                      checked={selectedWhitelistAccount === beneficiary.id}
                      onChange={() => setValue('account', beneficiary.id)}
                      value={beneficiary.id}
                      {...register('account')}
                    />
                  </ListItemIcon>
                  <ListItemText primary={beneficiary.accountNumber} secondary={`${beneficiary.name} / ${beneficiary.bankName || beneficiary.iban} / ${beneficiary.currency}`} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setConfirmOpen(true)
                        setWhitelistedAccountToBeDeleted(beneficiary)
                      }}
                      edge="end"
                      aria-label="delete"
                    >
                      <IndeterminateCheckBoxIcon color='error' />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
          <FormHelperText error>{errors?.account?.message}</FormHelperText>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item >
              <Typography className={classes.withdrawal}>
                {formatMessage({ id: 'withdrawal.available-balance' })}: {balanceData?.availableBalance.toLocaleString('en-UK', { style: 'currency', currency: accountSelected.currency })}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip
                title={infoMessage}
                arrow
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <InputText
            required
            errors={errors}
            type="number"
            name="amount"
            control={control}
            label={formatMessage({ id: 'withdrawal.amount' })}
          />
          <InputText
            errors={errors}
            type="text"
            name="reference"
            control={control}
            label={formatMessage({ id: 'withdrawal.reference' })}
            inputProps={{ maxLength: MAX_REFERENCE_MSG_LEN }}
          />
        </Grid>
        <Grid item>
          <Button
            disabled={isCreating}
            variant="extended"
            type="submit"
            color="success"
            aria-label="add"
            icon="send"
            onClick={onSubmit}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'withdrawal.submit' })}
          </Button>
          <Button
            disabled={scheduledWithdrawals.length >= MAX_SCHEDULED_WITHDRAWALS || isCreatingSchedule}
            variant="extended"
            type="submit"
            color="success"
            aria-label="add"
            icon="schedule"
            onClick={() => setShouldScheduledWithdrawalDialogOpen(true)}
            className={classes.buttonSpacing}
          >
            {formatMessage({ id: 'withdrawal-tab.create-schedule' })}
          </Button>
          <Button className={classes.buttonSpacing}>{formatMessage({ id: 'withdrawal.cancel' })}</Button>
        </Grid>

        <Grid item>
          <DataGrid
            title={formatMessage({ id: 'withdrawal-tab.current-scheduled-withdrawals' })}
            name="withdrawals_scheduled"
            disableExport
            disableColumnVisibility
            columns={[
              { name: 'when', title: formatMessage({ id: 'withdrawal-tab.when' }) },
              { name: 'amount', title: formatMessage({ id: 'withdrawal-tab.amount' }) },
              { name: 'account', title: formatMessage({ id: 'withdrawal-tab.account' }) },
              { name: 'reference', title: formatMessage({ id: 'withdrawal-tab.reference' }) },
              { name: 'time', title: formatMessage({ id: 'withdrawal-tab.time' }) },
            ]}
            data={scheduledWithdrawals}
            actions={[
              {
                icon: 'edit',
                tooltip: formatMessage({ id: 'withdrawal-tab.edit' }),
                onClick: (_, tableRow) => {
                  Object.assign(scheduledWithdrawalBeingEditedRef, { current: scheduledWithdrawalLookup.get(tableRow.id) })
                  setShouldScheduledWithdrawalDialogOpen(true)
                }
              },
              {
                icon: 'delete',
                tooltip: formatMessage({ id: 'withdrawal-tab.delete-schedule' }),
                onClick: (_, tableRow) => {
                  Object.assign(scheduledWithdrawalBeingDeletedRef, { current: tableRow })
                  setShouldScheduledWithdrawalDeleteDialogOpen(true)
                }
              }
            ]}
            pagination={{ disabled: true, pageSize: 999999 }}
            extra={<RefreshButton onClick={() => refetchScheduledWithdrawals()} />}

          />
        </Grid>
      </Grid>
      {shouldScheduledWithdrawalDialogOpen && (
        <ScheduledWithdrawalDialog
          open={shouldScheduledWithdrawalDialogOpen}
          onClose={handleScheduledWithdrawalDialogClose}
          whitelist={whitelist}
          scheduledWithdrawal={scheduledWithdrawalBeingEditedRef.current}
          onSubmit={
            scheduledWithdrawalBeingEditedRef.current
              ? handleScheduledWithdrawalEdit
              : handleWithdrawalSchedule
          }
        />
      )}
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => deleteWhitelistedAccountMutation(whitelistedAccountToBeDeleted.id)}
      >
        <Typography gutterBottom>{formatMessage({ id: 'withdrawal.are-you-sure-you-want-to-delete-this-whitelisted-account' })}</Typography>
        <Typography variant="h6">{whitelistedAccountToBeDeleted?.accountNumber} / {whitelistedAccountToBeDeleted?.bankName || whitelistedAccountToBeDeleted?.iban}</Typography>
      </ConfirmDialog>
      {mfaOpen && (
        <MFACodeAuthorizeDialogForm
          open={mfaOpen}
          setOpen={setMfaOpen}
          onConfirm={mfaCallback}
          isLoading={isCreating}
          tryAgainAt={tryAgainAtRef.current || null}
        />
      )}
      {shouldScheduledWithdrawalDeleteDialogOpen && (
        <ConfirmDialog
          open={shouldScheduledWithdrawalDeleteDialogOpen}
          setOpen={setShouldScheduledWithdrawalDeleteDialogOpen}
          onConfirm={handleScheduledWithdrawalDelete}
        >
          <Typography gutterBottom>{formatMessage({ id: 'withdrawal-tab.are-you-sure-you-want-to-delete-this-scheduled' })}?</Typography>
          <Box p={2}>
            <table>
              <tr>
                <td>{formatMessage({ id: 'withdrawal-tab.account' })}:&nbsp;&nbsp;</td>
                <td><code>{scheduledWithdrawalBeingDeletedRef.current?.account ?? '-'}</code></td>
              </tr>
              <tr>
                <td>{formatMessage({ id: 'withdrawal-tab.time' })}:&nbsp;&nbsp;</td>
                <td><code>{scheduledWithdrawalBeingDeletedRef.current?.time ?? '-'}</code></td>
              </tr>
              <tr>
                <td>{formatMessage({ id: 'withdrawal-tab.reference' })}:&nbsp;&nbsp;</td>
                <td><code>{scheduledWithdrawalBeingDeletedRef.current?.reference ?? '-'}</code></td>
              </tr>
              <tr>
                <td>{formatMessage({ id: 'withdrawal-tab.amount' })}:&nbsp;&nbsp;</td>
                <td><code>{scheduledWithdrawalBeingDeletedRef.current?.amount ?? '-'}</code></td>
              </tr>
              <tr>
                <td>{formatMessage({ id: 'withdrawal-tab.when' })}:&nbsp;&nbsp;</td>
                <td><code>{scheduledWithdrawalBeingDeletedRef.current?.when ?? '-'}</code></td>
              </tr>
            </table>
          </Box>
        </ConfirmDialog>
      )}
    </>
  )
}

Withdrawal.propTypes = {
  accountSelected: PropTypes.object,
  handleSetTabSelected: PropTypes.func,
  companyWallet: PropTypes.object,
}

export default Withdrawal
