import { connect } from 'react-redux'
import Component from './CommercialLinksList.js'
import { onNavigationChange } from "store/routes"
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'
import { fetchCommercialLinks, patchCommercialLink } from 'store/commercialLinks'

export const mapState = ({ auth, commercialLinks, companies }) => ({
  currUserCompanyId: auth.companyId,
  currUserCompany: companies.currUserCompany,
  commercialLinks,
  companies: companies.searchCompany.data,
  snackbar: commercialLinks?.snackbar
})

export const mapDispatch = dispatch => ({
  push: data => dispatch(onNavigationChange(data)),
  fetchCommercialLinks: data => dispatch(fetchCommercialLinks(data)),
  searchCompanies: (data) => dispatch(searchCompanies(data)),
  patchCommercialLink: data => dispatch(patchCommercialLink(data)),
  clearSearchCompaniesData: () => dispatch(clearSearchCompaniesData())
})

export default connect(mapState, mapDispatch)(Component)
