import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { ACTIONS } from 'store/routes'
import { performFetchRoutes } from '../requests'

export function* fetchRoutes() {
  const { FETCH_ROUTES_SUCCESS, FETCH_ROUTES_ERROR } = ACTIONS
  try {
    let { data } = yield call(performFetchRoutes)
    yield put({ type: FETCH_ROUTES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: FETCH_ROUTES_ERROR })
  }
}

const getRoutes = state => state.routes

export function* navigate({ payload }) {
  const data = yield select(getRoutes)
  if(data.routes.includes(payload)) {
    yield put(push(payload))
  } else {
    const url = `${window.location.origin}${payload}`;
    window.location.href = url;
  }
}

export default function* rootSaga() {
  const { FETCH_ROUTES, ON_NAVIGATION_CHANGE } = ACTIONS
  yield all([
    takeLatest(FETCH_ROUTES, fetchRoutes),
    takeLatest(ON_NAVIGATION_CHANGE, navigate)
  ])
}