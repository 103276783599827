export const initialFilterValues = {
  currentPage: 0,
  itemsPerPage: 50,
  status: null,
  transactionType: null,
}

// Keeping unused types commented for information 
export const TRANS_TYPES = {
  ALL: '(all)',
  Remittance_Debit: 'Withdrawal',
  // Remittance_Debit_External: 'NOT ALLOWED',
  Remittance_Reversal: 'Failed withdrawal',
  Wallet_Credit_Mode_Offline: 'Deposit',
  Wallet_Credit_Mode_Offline_ThirdParty: 'Third Party Deposit',
  Customer_Wallet_Debit_Fund_Transfer: 'TL Pay - Debit',
  Customer_Wallet_Credit_Fund_Transfer: 'TL Pay - Credit',
  Customer_Wallet_Debit_Cross_Region: 'TL Pay - X-Regional Debit',
  Customer_Wallet_Credit_Cross_Region: 'TL Pay - X-Regional Credit',
  // todo: map revolut's transaction types
}

export const TRANS_STATUS = {
  ALL: '(all)',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  DECLINED: 'Declined',
  REVERSAL: 'Reversal',
  BLOCKED: 'Blocked',
  // todo: map revolut's transaction states
}

export const SIGNUP_STEPS = {
  INTRODUCTION: 'INTRODUCTION',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  PROVIDER_TERMS_AND_CONDITIONS: 'PROVIDER_TERMS_AND_CONDITIONS',
  CORPORATE_DETAILS: 'CORPORATE_DETAILS',
  KYB: 'KYB',
  KYC: 'KYC',
  STATUS: 'STATUS',
  RFI: 'RFI',
  IN_PROGRESS: 'IN_PROGRESS',
}
