import { delay } from 'redux-saga'
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { ACTIONS as ROUTES_ACTIONS } from 'store/routes' 
import { USER_AUTH_STORAGE_KEYS, ROUTE_URL, USER_ROLES } from 'constants.js'
import { dismissErrorSnackBar, takeFirst, getRouterQueryParam } from 'utils/generators'
import { localStorage } from 'utils/storage'
import { ACTIONS } from 'store/auth'
import {
  performLogin,
  performLogout,
  performConfirmMFACode,
  performResetPassword,
  performSetupMFA,
  performResetPasswordByToken,
  performSetPassword,
  performRefresh,
  performGetTokenExpiry
} from '../requests'
import {
  performGetNotifications
} from '../../notifications/requests'
import { toast } from 'react-toastify'
import { AuthError } from 'utils/errors'

const { setItem } = localStorage

function* handleDefaultErrors(error) {
  if (error) {
    yield call(putError, error.message)
  }
}

// Clear only local storage auth keys
export function clearUserAuthLocalStorage() {
  Object.values(USER_AUTH_STORAGE_KEYS).forEach(item => localStorage.removeItem(item))
}

export function* putError(error) {
  yield put({
    type: ACTIONS.SET_ERROR,
    payload: error
  })
  yield delay(1000)
  yield put({
    type: ACTIONS.SET_ERROR,
    payload: false
  })
  yield put({
    type: ACTIONS.SET_FETCHING,
    payload: false
  })
}

export const getAuth = async authDataRaw => {
  if (!authDataRaw) {
    return null
  }
  const authData = authDataRaw.auth
  const { getItem } = localStorage

  const id = getItem(USER_AUTH_STORAGE_KEYS.id)
  const token = getItem(USER_AUTH_STORAGE_KEYS.token)
  const email = getItem(USER_AUTH_STORAGE_KEYS.email)
  const username = getItem(USER_AUTH_STORAGE_KEYS.username)
  const companyId = getItem(USER_AUTH_STORAGE_KEYS.companyId)
  const name = getItem(USER_AUTH_STORAGE_KEYS.name)
  const role = getItem(USER_AUTH_STORAGE_KEYS.role)
  if (id && token && username && companyId && name && role) {
    const expiry = await performGetTokenExpiry()

    if (expiry <= 0) {
      toast.error('Session expired. Please login again.')
      return null
    }
    return { id, token, username, companyId, name, role, email }
  } else if (!authData || !authData.username || !authData.password) {
    return null
  }
  clearUserAuthLocalStorage()
  const loginResponse = await performLogin(authData)
  return {
    ...loginResponse,
    performedLogin: true
  }
}

// Sagas
export function* fetchAuth(action, saveAuthData) {
  const { FETCH_AUTH_FAILURE, SETUP_MFA, CONFIRM_MFA, LOGOUT } = ACTIONS
  try {
    const result = yield call(getAuth, action.payload)
    if (!result || !(result.token || Reflect.has(result, 'is2FASetup'))) {
      yield put({ type: LOGOUT })
      throw result
    }
    if (result.is2FASetup) {
      yield put({
        type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
        payload: ROUTE_URL.confirmAuthenticator
      })
      yield put({
        type: CONFIRM_MFA,
        payload: { accessToken: result?.accessToken, email: action?.payload?.auth?.username }
      })
      toast.dismiss()
      return
    }
    if (Reflect.has(result, 'is2FASetup') && result.is2FASetup === false) {
      yield put({
        type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
        payload: ROUTE_URL.setupAuthenticator
      })
      yield put({
        type: SETUP_MFA,
        payload: { ...result, email: action?.payload?.auth?.username }
      })
      toast.dismiss()
      return
    }
    yield call(logInUser, result, action, saveAuthData)
    if (result.performedLogin) {
      // Reload window on login to prevent cache issues
      // It was decided to implement this instead of a proper solution
      toast.dismiss()
      window.location.reload(true)
    }
  } catch (error) {
    if (error !== null) {
      yield put({
        type: FETCH_AUTH_FAILURE,
        payload: {
          error: error?.message || 'Unable to log in',
          ...(error instanceof AuthError) && { tryAgainAt: error.tryAgainAt }
        }
      })
      yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
    }
  }
}

export function* getUnreadNotifications() {
  const { SET_UNREAD_NOTIFICATIONS, FETCH_AUTH_FAILURE } = ACTIONS
  let total
  try {
    const { data } = yield call(performGetNotifications, { 'isDismissed[eq]': false, limit: 1 })
    total = data?.length ? data[0].resultTotal : 0
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({ type: FETCH_AUTH_FAILURE, payload: { error: 'Error fetching unread notifications' } })
  } finally {
    yield put({ type: SET_UNREAD_NOTIFICATIONS, payload: total })
  }
}

export function* logInUser(result, action, saveAuthData = true) {
  const { FETCH_AUTH_SUCCESS, FETCH_AUTH_FAILURE } = ACTIONS
  try {
    const auth = {
      ...result,
      email: result.email || action.payload.auth.username,
      username: result.name || action.payload.auth.username,
      isAdmin: result.role === USER_ROLES.ADMIN
    }
    if (saveAuthData) {
      setItem(USER_AUTH_STORAGE_KEYS.id, auth.id)
      setItem(USER_AUTH_STORAGE_KEYS.token, auth.token)
      setItem(USER_AUTH_STORAGE_KEYS.email, auth.email)
      setItem(USER_AUTH_STORAGE_KEYS.username, auth.username)
      setItem(USER_AUTH_STORAGE_KEYS.companyId, auth.companyId)
      setItem(USER_AUTH_STORAGE_KEYS.name, auth.name)
      setItem(USER_AUTH_STORAGE_KEYS.role, auth.role)
    }
    yield put({
      type: FETCH_AUTH_SUCCESS,
      payload: auth
    })
    yield call(getUnreadNotifications)
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({ type: FETCH_AUTH_FAILURE, payload: { error: 'Error fetching auth' } })
  }
}

export function* fetchMFAQrCode(action) {
  const { SETUP_MFA_SUCCESS, SETUP_MFA_FAILURE } = ACTIONS
  try {
    const result = yield call(performSetupMFA, action.payload)
    if (!result?.imageUrl) {
      throw result
    }
    yield put({
      type: SETUP_MFA_SUCCESS,
      payload: result
    })
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({ type: SETUP_MFA_FAILURE, payload: { error: 'Error setting mfa up' } })
  }
}

export function* refreshToken() {
  const { REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } = ACTIONS
  try {
    const res = yield call(performRefresh)
    if (!res?.token) {
      throw res
    }
    yield call(setItem, USER_AUTH_STORAGE_KEYS.token, res.token)
    yield call(toast.success, 'Your session has been refreshed successfully.')
    yield put({
      type: REFRESH_TOKEN_SUCCESS,
      payload: res
    })
    
  } catch (err) {
    yield call(handleDefaultErrors, err)
    yield put({ type: REFRESH_TOKEN_FAILURE, payload: { error: 'Error when refreshing login session' } })
  }
}

const getAuthState = state => {
  return state.auth
}

export function* resetMFA(action) {
  const { RESET_MFA_SUCCESS, SETUP_MFA_SUCCESS, RESET_MFA_FAILURE } = ACTIONS
  try {
    const { username, password, recoveryKey } = action.payload
    const resultLogin = yield call(performLogin, {
      username,
      password
    })
    if (!resultLogin?.accessToken) {
      throw resultLogin
    }
    const { accessToken } = resultLogin
    const result = yield call(performSetupMFA, ({ accessToken, recoveryKey }))
    if (!result?.imageUrl) {
      throw result
    }
    yield put({
      type: RESET_MFA_SUCCESS,
      payload: { accessToken }
    })
    yield put({
      type: SETUP_MFA_SUCCESS,
      payload: result
    })
    yield put({
      type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
      payload: ROUTE_URL.setupAuthenticator
    })
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({ type: RESET_MFA_FAILURE, payload: { error: 'Error resetting mfa up' } })
  }
}

export function* confirmMFACode(action) {
  const { CONFIRM_MFA_CODE_SUCCESS, CONFIRM_MFA_CODE_FAILURE } = ACTIONS
  try {
    const auth = yield select(getAuthState)
    const result = yield call(performConfirmMFACode, {
      otpToken: action.payload,
      accessToken: auth.accessToken
    })
    if (!result || !result.token) {
      throw result
    }
    yield call(logInUser, { email: auth.email, ...result })
    yield put({
      type: CONFIRM_MFA_CODE_SUCCESS,
      payload: result
    })
    yield put({
      type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
      payload: ROUTE_URL.home
    })
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({
      type: CONFIRM_MFA_CODE_FAILURE,
      payload: {
        error: 'Error setting mfa up',
        ...(error instanceof AuthError) && { tryAgainAt: error.tryAgainAt }
      }
    })
  }
}

// ToDo: Fix the result bug - This yield works, but we don't get the result.
export function* setPassword(action) {
  yield call(performSetPassword, action.payload)
}

export function* resetPassword(action) {
  const { FETCH_AUTH_FAILURE } = ACTIONS
  try {
    const result = yield call(performResetPassword, action.payload)
    if (!result) {
      throw Error('Unable to reset password')
    }
  } catch (error) {
    yield put({ type: FETCH_AUTH_FAILURE, payload: { error: 'Error changing password' } })
  }
}

export function* resetPasswordToken(action) {
  const { RESET_PASSWORD_TOKEN_SUCCESS, RESET_PASSWORD_TOKEN_FAILURE, CLOSE_SNACKBAR } = ACTIONS
  try {
    const token = yield getRouterQueryParam('token')
    yield call(performResetPasswordByToken, { ...action.payload, token })
    yield put({
      type: RESET_PASSWORD_TOKEN_SUCCESS
    })
    yield put(replace(ROUTE_URL.login))
  } catch (error) {
    yield put({ type: RESET_PASSWORD_TOKEN_FAILURE, payload: { error: error ? error.message : 'Error resetting password' } })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

export function* logout() {
  try {
    clearUserAuthLocalStorage()
    yield call(performLogout)
    yield put(replace(ROUTE_URL.login))
  } catch (error) {
    const body = yield (error).json()
    yield call(putError, `${body.errors[0].status} ${body.errors[0].title}`)
  }
}

export function* getTokenExpiry() {
  const { GET_TOKEN_EXPIRY_SUCCESS, GET_TOKEN_EXPIRY_FAILURE } = ACTIONS
  try {
    const expiry = yield call(performGetTokenExpiry)

    yield put({
      type: GET_TOKEN_EXPIRY_SUCCESS,
      payload: expiry,
    })
  } catch (error) {
    yield call(handleDefaultErrors, error)
    yield put({
      type: GET_TOKEN_EXPIRY_FAILURE,
      payload: { error: 'Error getting token expiration' },
    })
  }
}

export default function* rootSaga() {
  const { FETCH_AUTH, SETUP_MFA, RESET_MFA, CONFIRM_MFA_CODE, REFRESH_TOKEN, SET_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_TOKEN, LOGOUT, GET_TOKEN_EXPIRY } = ACTIONS
  yield all([
    takeEvery(FETCH_AUTH, fetchAuth),
    takeFirst(SETUP_MFA, fetchMFAQrCode),
    takeFirst(RESET_MFA, resetMFA),
    takeFirst(CONFIRM_MFA_CODE, confirmMFACode),
    takeFirst(REFRESH_TOKEN, refreshToken),
    takeFirst(SET_PASSWORD, setPassword),
    takeFirst(RESET_PASSWORD, resetPassword),
    takeLatest(RESET_PASSWORD_TOKEN, resetPasswordToken),
    takeFirst(LOGOUT, logout),
    takeFirst(GET_TOKEN_EXPIRY, getTokenExpiry)
  ])
}
