import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { currencyFormatter } from '../../utils'
import { useStyles } from './styles'

const CurrencyCellContent = memo(({ value, currency, row }) => {
  const styles = useStyles()
  if (typeof value === 'object') {
    return value
  }
  if(isNaN(value)) {
    return <div className={styles.currencyCellContent}>
      N/A
    </div>    
  }
  return (
    <div className={styles.currencyCellContent}>
      { currencyFormatter({ value, currency: currency || row?.currency }) }
    </div>
  )
})

CurrencyCellContent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string
}

CurrencyCellContent.displayName = 'CurrencyCellContent'

export default CurrencyCellContent
