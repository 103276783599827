import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, EWALLET_PROVIDER } from 'constants.js'
import { useStyles } from '../styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import { getReadableErrorMessage } from 'utils/errors'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Grid from '@material-ui/core/Grid'
import { useIntl } from 'react-intl'
import NiumIcon from './NiumIcon'
import RevolutIcon from './RevolutIcon'

function checkAccountsEqual(acc1, acc2) {
  if (!acc1 || !acc2) return false
  return acc1.walletId === acc2.walletId &&
    acc1.provider === acc2.provider &&
    acc1.currency === acc2.currency
}

const Balances = ({ accountSelected, setAccountSelected, companyWallet }) => {
  const classes = useStyles()
  const { companyId } = companyWallet || {}
  const { data: balanceData } = useQuery(`balance-${companyId}`, () => get(`${API_URL}/e-wallet/balance/${companyId}`), {
    retry: false,
    onError: (error) => toast.error(getReadableErrorMessage(error))
  })
  const { formatMessage } = useIntl()

  useEffect(() => {
    const accountSelectedUpdated = (balanceData || []).find(ele => checkAccountsEqual(ele, accountSelected))
    if (accountSelectedUpdated) {
      setAccountSelected(accountSelectedUpdated)
    } else if (balanceData && balanceData.length) {
      setAccountSelected(balanceData[0])
    }
  }, [balanceData, accountSelected, setAccountSelected])

  const balances = balanceData || []

  if (accountSelected === null) {
    const defaultBalance = balances.find(balance => balance.default)
    if (defaultBalance) {
      setAccountSelected(defaultBalance)
    }
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {formatMessage({ id: 'tlpay.my-balances' })}
      </Typography>
      <Grid container spacing={2} className={classes.gridPlaceholder}>
        {balances.sort((a, b) => b.default - a.default).map((balance, index) => (
          <Grid key={index} item>
            <Card className={[classes.card, classes.balanceCard, checkAccountsEqual(accountSelected, balance) && classes.cardSelected]} variant="outlined">
              <CardActionArea onClick={() => setAccountSelected(balance)}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    {balance && balance.currency}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {balance && balance.balance.toLocaleString('en-UK', { style: 'currency', currency: balance.currency })}
                  </Typography>
                  <div className={classes.providerIconContainer}>
                    {[EWALLET_PROVIDER.NIUM_EU, EWALLET_PROVIDER.NIUM_UK].includes(balance.provider) && (
                      <NiumIcon style={{ width: '100%', height: '100%' }} />
                    )}
                    {[EWALLET_PROVIDER.REVOLUT].includes(balance.provider) && (
                      <RevolutIcon style={{ width: '100%', height: '100%' }} />
                    )}
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

Balances.propTypes = {
  accountSelected: PropTypes.object,
  setAccountSelected: PropTypes.func,
  companyWallet: PropTypes.object,
}

export default Balances 
