import { all } from 'redux-saga/effects'
import auth from './auth/saga'
import businessTerms from './businessTerms/saga'
import countries from './countries/saga'
import registration from './registration/saga'
import admin from './admin/saga'
import users from './users/saga'
import transactions from './transactions/saga'
import csv from './csv/saga'
import tasks from './tasks/saga'
import companies from './companies/saga'
import invoices from './invoices/saga'
import remittances from './remittances/saga'
import links from './commercialLinks/saga'
import payment from './paymentProfiles/saga'
import dataDelivery from './dataDelivery/saga'
import notifications from './notifications/saga'
import ptxContacts from './ptxContacts/saga'
import routesSaga from './routes/saga'

export default function* rootSaga() {
  yield all([
    auth(),
    businessTerms(),
    countries(),
    registration(),
    admin(),
    users(),
    transactions(),
    csv(),
    dataDelivery(),
    notifications(),
    tasks(),
    companies(),
    invoices(),
    remittances(),
    links(),
    payment(),
    ptxContacts(),
    routesSaga(),
  ])
}
