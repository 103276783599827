import { getKeyByValue } from "utils/functions"
import { 
  STATUS_FILTER_OPTIONS,
} from "./constants"

export const filterValuesToQueryParams = (filterValues) => {
  const filtersToSet = {}

  // Payment Method
  if (filterValues.paymentPlatform && filterValues.paymentPlatform.toLowerCase() !== 'all') {
    filtersToSet['payment_platform[eq]'] = filterValues.paymentPlatform
  }

  // Company
  if (filterValues.companyName) {
    filtersToSet['company.name[eq]'] = filterValues.companyName
  }

  // Submission
  if (filterValues.submissionAt && filterValues.submissionAt.startDate && filterValues.submissionAt.endDate) {
    const { startDate, endDate } = filterValues.submissionAt

    filtersToSet['submission_at[gte]'] = startDate
    filtersToSet['submission_at[lte]'] = endDate
  }

  // Bank Date
  if (filterValues.bankDate && filterValues.bankDate.startDate && filterValues.bankDate.endDate) {
    const { startDate, endDate } = filterValues.bankDate

    filtersToSet['payment_at[gte]'] = startDate.toISOString()
    filtersToSet['payment_at[lte]'] = endDate.toISOString()
  }

  // Counter Party
  if (filterValues.processingEntityName) {
    filtersToSet['pe.name[eq]'] = filterValues.processingEntityName
  }

  // Net Amount
  if (filterValues.netAmountInCents && filterValues.netAmountInCents.value) {
    filtersToSet[`net_amount_in_cents[${filterValues.netAmountInCents.type}]`] = parseFloat(filterValues.netAmountInCents.value).toFixed(2) * 100
  }

  // Status
  if (filterValues.status && filterValues.status.toLowerCase() !== STATUS_FILTER_OPTIONS.ALL.toLowerCase()) {
    if(filterValues.status === getKeyByValue(STATUS_FILTER_OPTIONS, STATUS_FILTER_OPTIONS.COMPLETED)) {
      filtersToSet['cleared_at[lte]'] = new Date().toISOString()
    } else if(filterValues.status === getKeyByValue(STATUS_FILTER_OPTIONS, STATUS_FILTER_OPTIONS.ESTIMATED)) {
      filtersToSet['cleared_at[gt]'] = new Date().toISOString()
      filtersToSet['collection_cleared_at[gt]'] = new Date().toISOString()
    } else if (filterValues.status === getKeyByValue(STATUS_FILTER_OPTIONS, STATUS_FILTER_OPTIONS.COLLECTION_CLEARED)) {
      filtersToSet['collection_cleared_at[lte]'] = new Date().toISOString()
      filtersToSet['cleared_at[gt]'] = new Date().toISOString()
    }
  }

  // Transaction type
  if (filterValues.type && filterValues.type !== 'ALL') {
    filtersToSet['type[eq]'] = filterValues.type
  }

  return filtersToSet
}
