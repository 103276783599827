import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import countries from './countries'
import registration from './registration'
import users from './users'
import transactions from './transactions'
import dataDelivery from './dataDelivery'
import notifications from './notifications'
import tasks from './tasks'
import businessTerms from './businessTerms'
import ptxContacts from './ptxContacts'
import { companies } from './companies'
import { invoices } from './invoices'
import { remittances } from './remittances'
import { commercialLinks } from './commercialLinks'
import { paymentProfiles } from './paymentProfiles'
import csv from './csv'
import routes from './routes'

const createRootReducer = (history) => combineReducers({
  auth,
  businessTerms,
  countries,
  registration,
  transactions,
  dataDelivery,
  notifications,
  tasks,
  users,
  companies,
  invoices,
  remittances,
  commercialLinks,
  paymentProfiles,
  ptxContacts,
  router: connectRouter(history),
  csv,
  routes
})

export default createRootReducer
