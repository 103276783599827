import { connect } from 'react-redux'
import Component from './CSVMenu'
import { uploadCSV, downloadCSV, setUploadCSVError } from 'store/csv'
import { fetchUncommittedInvoices, commitInvoices, updateInvoice, updateInvoices, deleteInvoices } from 'store/invoices'
import { onNavigationChange } from "store/routes"

export const mapState = ({ auth, invoices, companies, csv }) => ({
  auth,
  currUserCompanyId: auth.companyId,
  invoices: invoices.uncommitted.list,
  isLoading: invoices.loading,
  snackbar: invoices.snackbar,
  currUserCompany: companies.currUserCompany,
  csv
})

export const mapDispatch = dispatch => ({
  push: data => dispatch(onNavigationChange(data)),
  uploadCSV: data => dispatch(uploadCSV(data)),
  downloadCSV: data => dispatch(downloadCSV(data)),
  fetchUncommittedInvoices: (data) => dispatch(fetchUncommittedInvoices(data)),
  updateInvoice: (data) => dispatch(updateInvoice(data)),
  updateInvoices: (data) => dispatch(updateInvoices(data)),
  commitInvoices: (data) => dispatch(commitInvoices(data)),
  deleteInvoices: (data) => dispatch(deleteInvoices(data)),
  setUploadCSVError: data => dispatch(setUploadCSVError(data))
})

export default connect(mapState, mapDispatch)(Component)
