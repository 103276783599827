import { QUICK_VIEWS } from 'constants.js'
import { filterValuesToQueryParams } from './utils'

export const BULK_ACTIONS = {
  ADD_REMITTANCE: 'Add Remittance',
  ACCEPT_SELECTED: 'Accept selected',
}

export const initialFilterValues = {
  bookingRef: '',
  partner: undefined,
  partnerAbtaId: '',
  leadName: '',
  createdAt: '',
  earliestDueDate: undefined,
  departureDate: undefined,
  status: undefined,
  settlementStatus: undefined,
  hideActioned: false,
  currentPage: 1,
  itemsPerPage: 50,
  transactionType: 'ALL',
}

const EXTENDED_FIELDS = [
  'transType',
  'bookingNettAmount',
  'bookingComAmount',
  'bookingSellAmount',
  'bookingNettTaxAmount',
  'bookingComTaxAmount',
  'bookingSellTaxAmount',
  'tktVchrNumber',
  'confNumber',
  'transPaxNo',
  'transProductType',
  'destination',
  'destCountry',
  'sellerInvoiceNo',
  'buyerBookingRef',
  'optional1',
  'optional2',
  'optional3',
  'bookedBy',
]

export const DEFAULT_HIDDEN_COLUMNS = {
  [QUICK_VIEWS.BUYER_CURRENT_WEEK]: [
    'tags',
    'id',
    'sellerIdType',
    'sellerId',
    'buyerName',
    'buyerIdType',
    'buyerId',
    'settlementStatus',
    'disbursementAt',
    'disbursementPaymentAt',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.BUYER_ALL_DUE]: [
    'tags',
    'id',
    'sellerIdType',
    'sellerId',
    'buyerName',
    'buyerIdType',
    'buyerId',
    'settlementStatus',
    'disbursementAt',
    'disbursementPaymentAt',
    'departureDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.BUYER_PAST]: [
    'tags',
    'id',
    'sellerIdType',
    'sellerId',
    'buyerName',
    'buyerIdType',
    'buyerId',
    'disbursementAt',
    'disbursementPaymentAt',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.SELLER_CURRENT_WEEK]: [
    'tags',
    'id',
    'sellerName',
    'sellerIdType',
    'sellerId',
    'buyerIdType',
    'settlementStatus',
    'disbursementAt',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.SELLER_ALL_DUE]: [
    'tags',
    'id',
    'sellerName',
    'sellerIdType',
    'sellerId',
    'buyerIdType',
    'settlementStatus',
    'disbursementAt',
    'departureDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.SELLER_PAST]: [
    'tags',
    'id',
    'sellerName',
    'sellerIdType',
    'sellerId',
    'buyerIdType',
    'disbursementAt',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.PE_CURRENT_WEEK]: [
    'tags',
    'id',
    'processingEntityCompanyName',
    'settlementStatus',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.PE_ALL_DUE]: [
    'tags',
    'id',
    'processingEntityCompanyName',
    'settlementStatus',
    'departureDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
  [QUICK_VIEWS.PE_PAST]: [
    'tags',
    'id',
    'processingEntityCompanyName',
    'departureDate',
    'earliestDueDate',
    'commercialLinkDisplayName',
    'updatedBy',
    'updatedAt',
    ...EXTENDED_FIELDS,
  ],
}

// Hidden options for ticket #918: Hide the Extended File options in the 'Column' selector in Transactions>Bookings
// See: https://zube.io/applied-blockchain/travel-ledger/tickets/918

// const financialColumns = [
//   'currency',
//   'transType',
//   'bookingNettAmount',
//   'bookingComAmount',
//   'bookingSellAmount',
//   'bookingNettTaxAmount',
//   'bookingComTaxAmount',
//   'bookingSellTaxAmount',
// ]

// const bookingColumns = ['tktVchrNumber', 'confNumber', 'transPaxNo', 'transProductType', 'destination', 'destCountry']

// const otherColumns = ['sellerIdType', 'buyerIdType', 'optional1', 'optional2', 'optional3']

export const columnChooserGroups = [
  // {
  //   groupLabel: 'Extended data',
  //   options: [
  //     { value: 'all', label: 'All', columns: [...financialColumns, ...bookingColumns, ...otherColumns] },
  //     {
  //       value: 'financial',
  //       label: 'Financial',
  //       columns: financialColumns,
  //     },
  //     {
  //       value: 'booking',
  //       label: 'Booking',
  //       columns: bookingColumns,
  //     },
  //     {
  //       value: 'other',
  //       label: 'Other',
  //       columns: otherColumns,
  //     },
  //   ],
  // },
]

export const initialBookingsReviewFilter = filterValuesToQueryParams(initialFilterValues)

export const REDACTED_FIELD = '---REDACTED---'