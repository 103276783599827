import { connect } from 'react-redux'
import { onNavigationChange } from "store/routes"
import Component from './RemittancesReview'
import { fetchRemittances, clearRemittances } from 'store/remittances'
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'
import { fetchLatestCycles } from 'store/invoices'

export const mapState = ({ auth, companies, invoices, remittances }) => ({
  remittances: remittances.list,
  currUserCompanyId: auth.companyId,
  currUserCompany:companies?.currUserCompany,
  searchedCompanies: companies.searchCompany.data,
  latestCollectionDates: invoices.latestCollectionDates,
  latestCollectionPayments: invoices.latestCollectionPayments,
  latestDisbursementDates: invoices.latestDisbursementDates,
  latestDisbursementPayments: invoices.latestDisbursementPayments,
  isCurrentCompanyProcessingEntity: companies?.currUserCompany?.isProcessingEntity,
  lastUpdate: remittances.lastUpdate,
})

export const mapDispatch = dispatch => ({
  redirectTo: (path) => dispatch(onNavigationChange(path)),
  fetchRemittances: (data) => dispatch(fetchRemittances(data)),
  clearRemittances: () => dispatch(clearRemittances()),
  searchCompanies: (data) => dispatch(searchCompanies(data)),
  clearSearchCompaniesData: () => dispatch(clearSearchCompaniesData()),
  fetchLatestCycles: () => dispatch(fetchLatestCycles())
})

export default connect(mapState, mapDispatch)(Component)
