import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { EWALLET_PROVIDER, NIUM_ONBOARD_STATUS, ROUTE_URL } from 'constants.js'
import { useStyles } from './styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Introduction from './components/Signup/Introduction'
import TermsAndConditions from './components/Signup/TermsAndConditions'
import ProviderTermsAndConditions from './components/Signup/ProviderTermsAndConditions'
import CorporateDetails from './components/Signup/CorporateDetails'
import KYBForm from './components/Signup/KYBForm/KYBForm'
import KYC from './components/Signup/KYC'
import RFI from './components/Signup/RFI'
import InProgress from './components/Signup/InProgress'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { SIGNUP_STEPS } from './constants'
import isEmpty from 'lodash/isEmpty'
import { validationSchema as validationSchemaUk } from './components/Signup/KYBForm/form-schemas/nium/validation/validationUK'
import { validationSchema as validationSchemaEu } from './components/Signup/KYBForm/form-schemas/nium/validation/validationEU'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { getRegionAndProvider } from './utils'

const Signup = ({ companyWallet, refetchWallets, initStep, shouldUpdateStepAccordingToStatus }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(initStep ?? SIGNUP_STEPS.INTRODUCTION)
  const [onboardResponse, setOnboardResponse] = useState(null)
  const [status, setStatus] = useState(NIUM_ONBOARD_STATUS.IN_PROGRESS)
  const [localLoaded, setLocalLoaded] = useState(false)
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {
    // isHQ,
    // name,
    // countryName,
    companyId,
    countryAlpha2,
    signup = {},
  } = companyWallet || {}

  const signupStorage = useMemo(() => 
    JSON.parse(localStorage.getItem(`@tldg:ewallet_signup_${companyId}`) || '{}'), 
    [companyId])
  
  // manage initialization
  const [initKey, setInitKey] = useState()
  // reset local storage data on change company
  useEffect(() => {
    if(companyId) {
      setLocalLoaded(false)
    }
  }, [companyId])

  useEffect(() => {
    // detect change - company + status
    const key = `${companyId}${signup.providerStatus}`
    if(initKey === key) {
      return
    }

    if (!initStep || shouldUpdateStepAccordingToStatus) {
      if (signup && !isEmpty(signup)) {
        setOnboardResponse(signup.data)
        setStatus(signup.providerStatus)
        setProvider(signup.provider)
        
        if (signup.providerStatus === NIUM_ONBOARD_STATUS.RFI_REQUESTED 
            || signup.providerStatus === NIUM_ONBOARD_STATUS.RFI_RESPONDED) {
          setActiveStep(SIGNUP_STEPS.RFI)
        } else if (signup.providerStatus === NIUM_ONBOARD_STATUS.IN_PROGRESS) {
          setActiveStep(SIGNUP_STEPS.KYC)
        } else if (signup.providerStatus === NIUM_ONBOARD_STATUS.ERROR) {
          setActiveStep(SIGNUP_STEPS.KYB)
        } else if (signup.providerStatus === NIUM_ONBOARD_STATUS.ACTION_REQUIRED) {
          setActiveStep(SIGNUP_STEPS.IN_PROGRESS)
        }
      }
    }

    if(signup && isEmpty(signup)) {
      setActiveStep(initStep ?? SIGNUP_STEPS.INTRODUCTION)
      setOnboardResponse(null)
      setStatus()
    }

    setInitKey(key)
  }, [signup, companyId, initKey, initStep, shouldUpdateStepAccordingToStatus])
  
  // set region and provider from wallet's country code
  const { region, provider: providerByRegion } = getRegionAndProvider(countryAlpha2)
  const [provider, setProvider] = useState(signup.provider || providerByRegion)
  const getStepContent = (step) => {
    switch (step) {
      case SIGNUP_STEPS.INTRODUCTION:
        return <Introduction
          hasExistingSignup={!!companyWallet?.signup}
          goToNextStep={() => history.push(ROUTE_URL.tlpayNiumSignup.replace(':companyId', encodeURIComponent(companyId)))}
        />
      case SIGNUP_STEPS.TERMS_AND_CONDITIONS:
        return <TermsAndConditions
          goToNextStep={() => setActiveStep(SIGNUP_STEPS.PROVIDER_TERMS_AND_CONDITIONS)}
        />
      case SIGNUP_STEPS.PROVIDER_TERMS_AND_CONDITIONS:
        return <ProviderTermsAndConditions
          provider={provider}
          goToNextStep={() => setActiveStep(SIGNUP_STEPS.CORPORATE_DETAILS)}
        />
      case SIGNUP_STEPS.CORPORATE_DETAILS:
        return <CorporateDetails
          countryAlpha2={countryAlpha2}
          signupStorage={signupStorage}
          goToNextStep={() => setActiveStep(SIGNUP_STEPS.KYB)}
          provider={provider}
          region={region}
        />
      case SIGNUP_STEPS.KYB:
        return <KYBForm
          companyId={companyId}
          provider={provider}
          signup={signup}
          goToNextStep={() => {
            refetchWallets()
          }}
          saveData={saveData}
        />
      case SIGNUP_STEPS.KYC:
        return <KYC data={onboardResponse} status={status} companyId={companyId} />
      case SIGNUP_STEPS.RFI:
        return <RFI data={onboardResponse} companyId={companyId} provider={provider} />
      case SIGNUP_STEPS.IN_PROGRESS:
        return <InProgress data={onboardResponse} />
      default:
        return 'Unknown step';
    }
  }
  
  const validationSchema = provider === EWALLET_PROVIDER.NIUM_EU ? validationSchemaEu : validationSchemaUk
  const formMethods = useForm({ resolver: joiResolver(validationSchema) })
  useEffect(() => {
    if (!localLoaded) {
      formMethods.reset(signupStorage)
      setLocalLoaded(true)
    }
  }, [signupStorage, formMethods, localLoaded])

  const saveData = async (sectionName) => {
    const values = formMethods.getValues()
    // manage error on quota exceeded
    let storageSaveResult = true
    try {
      localStorage.setItem(`@tldg:ewallet_signup_${companyId}`, JSON.stringify(values))
    } catch(e) {
      console.error(e)
      storageSaveResult = false
    }
    if(storageSaveResult) {
      toast.success(formatMessage({ id: 'tlpay.data-saved-successfully' }), { autoClose: 2000 })
    } else {
      toast.error(formatMessage({ id: 'tlpay.the-total-size-of-all-documents-uploaded-has-exceeded-your-browser-s-memory-you-may-continue-filling-out-the-form-and-submit-the-application-however-if-you-want-to-complete-at-a-later-time-then-only-data-saved-before-this-warning-will-be-available' }))
    }

    if(sectionName) {
      const validationResult = await formMethods.trigger(sectionName)
      if(validationResult) {
        toast.success(formatMessage({ id: 'tlpay.validation-successful' }), { autoClose: 2000 })
      } else {
        toast.warn(formatMessage({ id: 'tlpay.validation-errors-found-please-correct-the-data-in-the-form' }))
      }
      return validationResult
    }
  }

  return (
      <Card className={classes.card} variant="outlined" >
        <CardContent>
          <FormProvider  {...formMethods}>
            {getStepContent(activeStep)}
          </FormProvider>
        </CardContent>
      </Card>
  )
}

Signup.propTypes = {
  companyWallet: PropTypes.object,
}

export default connect(Signup)
