import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { ACTIONS as ROUTES_ACTIONS } from 'store/routes'
import { API_URL, ROUTE_URL } from 'constants.js'
import { ACTIONS } from 'store/commercialLinks'
import { ACTIONS as AUTH_ACTIONS } from 'store/auth'
import { get, patch, post } from 'utils/api'
import { dismissErrorSnackBar, dismissSnackBar } from 'utils/generators'

export function* fetchCommercialLinks({ payload }) {
  const url = `${API_URL}/commercial-links`
  try {
    const { FETCH_COMMERCIAL_LINKS_SUCCESS } = ACTIONS
    const result = yield call(
      get,
      url,
      payload
    )
    yield put({
      type: FETCH_COMMERCIAL_LINKS_SUCCESS,
      payload: result
    })
  } catch (e) {
    console.error(e)
  }
}

export function* postLink({ payload }) {
  const url = `${API_URL}/commercial-links`
  try {
    const { ADD_COMMERCIAL_LINKS_SUCCESS, CLOSE_SNACKBAR } = ACTIONS
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    const result = yield call(
      post,
      url,
      payload
    )
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    if (result.errors) {
      throw result.response
    }
    yield put({
      type: ADD_COMMERCIAL_LINKS_SUCCESS,
      payload: result
    })
    yield fork(dismissSnackBar, CLOSE_SNACKBAR)
    yield put({
        type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
        payload: ROUTE_URL.commercialLinksList
      })
  } catch (e) {
    console.error(e)
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    const { ADD_COMMERCIAL_LINKS_ERROR, CLOSE_SNACKBAR } = ACTIONS
    yield put({
      type: ADD_COMMERCIAL_LINKS_ERROR,
      payload: `${e.data.errors[0].detail || e.data.errors[0].title}`
    })
    yield fork(dismissErrorSnackBar, CLOSE_SNACKBAR)
  }
}

function* patchCommercialLink({ payload }) {
  try {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: true })
    let result
    if (Array.isArray(payload)) {
      result = yield Promise.allSettled(
        payload.map(commercialLink => {
          const { id, ...attributes } = commercialLink
          const url = `${API_URL}/commercial-links/${id}`
          return patch(url, attributes)
        })
      )
      yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
      const rejected = result.filter(r => r.status === 'rejected')
      if (rejected.length) {
        throw new Error(rejected?.reason?.data?.errors?.[0]?.detail)
      }
    } else {
      const url = `${API_URL}/commercial-links/${payload.id}`
      result = yield call(patch, url, payload.objLink)
      yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
      if (result.errors) {
        throw result.response
      }
      yield put({ type: ACTIONS.PATCH_COMMERCIAL_LINK_SUCCESS, payload: result })
      yield fork(dismissSnackBar, ACTIONS.CLOSE_SNACKBAR)
      yield put({
        type: ROUTES_ACTIONS.ON_NAVIGATION_CHANGE,
        payload: ROUTE_URL.commercialLinksList
      })
    }
  } catch (e) {
    yield put({ type: AUTH_ACTIONS.SET_FETCHING, payload: false })
    yield put({
      type: ACTIONS.PATCH_COMMERCIAL_LINK_ERROR,
      payload: `${e.data?.errors?.[0]?.status} ${e?.data?.errors?.[0]?.title}`
    })
    yield fork(dismissErrorSnackBar, ACTIONS.CLOSE_SNACKBAR)
  }
}

export function* searchCommercialLinks(actions) {
  const url = `${API_URL}/commercial-links`
  try {
    const result = yield call(
      get,
      url,
      actions.payload
    )
    yield put({
      type: ACTIONS.SEARCH_COMMERCIAL_LINK_SUCCESS,
      payload: result
    })
  } catch (e) {
    console.error(e)
  }
}

function* watchFetchCommercialLinks() {
  const { FETCH_COMMERCIAL_LINKS } = ACTIONS
  yield takeLatest(FETCH_COMMERCIAL_LINKS, fetchCommercialLinks)
}

function* watchPostLink() {
  const { ADD_COMMERCIAL_LINKS } = ACTIONS
  yield takeLatest(ADD_COMMERCIAL_LINKS, postLink)
}

function* watchPatchCommercialLink() {
  yield takeLatest(ACTIONS.PATCH_COMMERCIAL_LINK, patchCommercialLink)
}

function* watchSearchCommercialLinks() {
  const { SEARCH_COMMERCIAL_LINK } = ACTIONS
  yield takeLatest(SEARCH_COMMERCIAL_LINK, searchCommercialLinks)
}

export default function* rootSaga() {
  yield all([
    watchFetchCommercialLinks(),
    watchPostLink(),
    watchPatchCommercialLink(),
    watchSearchCommercialLinks()
  ])
}
