import { connect } from 'react-redux'
import Component from './CommercialLinkDetails.js'
import { replace } from 'connected-react-router'
import { postCommercialLinks, patchCommercialLink } from 'store/commercialLinks'
import { searchCompanies, clearSearchCompaniesData } from 'store/companies'
import { onNavigationChange } from 'store/routes'

export const mapState = ({ auth, companies, paymentProfiles, commercialLinks }) => ({
  currUserCompanyId: auth.companyId,
  currUserCompany: companies.currUserCompany,
  companies: companies.searchCompany.data,
  paymentList: paymentProfiles.payment,
  snackbar: commercialLinks.snackbar
})

export const mapDispatch = dispatch => ({
  push: data => dispatch(onNavigationChange(data)),
  replace: data => dispatch(replace(data)),
  postCommercialLinks: data => dispatch(postCommercialLinks(data)),
  patchCommercialLink: data => dispatch(patchCommercialLink(data)),
  searchCompanies: data => dispatch(searchCompanies(data)),
  clearSearchCompaniesData: () => dispatch(clearSearchCompaniesData())
})

export default connect(mapState, mapDispatch)(Component)
