import { fullName } from 'utils/functions'

// Action Names
const FETCH_ROUTES = fullName('routes', 'FETCH_ROUTES')
const FETCH_ROUTES_SUCCESS = fullName('routes', 'FETCH_ROUTES_SUCCESS')
const FETCH_ROUTES_ERROR = fullName('routes', 'FETCH_ROUTES_ERROR')
const ON_NAVIGATION_CHANGE = fullName('routes', 'ON_NAVIGATION_CHANGE')

export const ACTIONS = {
    FETCH_ROUTES,
    FETCH_ROUTES_SUCCESS,
    FETCH_ROUTES_ERROR,
    ON_NAVIGATION_CHANGE
}

export const fetchRoutes = () => ({
    type: FETCH_ROUTES
})

export const onNavigationChange = (route) => ({
    type: ON_NAVIGATION_CHANGE,
    payload: route
})

const initialState = {
    routes: []
}

const routes = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROUTES_SUCCESS:
        return {
            ...state,
            routes: action.payload
        }
        default:
        return state
    }
}
export default routes
