import { Box, TextField, Typography } from '@material-ui/core'
import Button from 'components/Button'
import React from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { isProduction } from 'constants.js'

const envCertificate = isProduction ? process.env.REACT_APP_REVOLUT_CERT_PROD : process.env.REACT_APP_REVOLUT_CERT
const CERTIFICATE = (envCertificate || '').replace(/\\n/g, '\n')

const Ol = styled('ol')(({ theme }) => ({
  '& > li:not(:last-child)': {
    paddingBottom: theme.spacing(1),
  }
}))

const getOauthRedirectUri = (host, signupId) => `${host}/tlpay/accounts/revolut/signups/${encodeURIComponent(signupId)}/complete`

const ConfigureRevolutSettings = ({ signupId, onComplete, onCancel }) => {
  const { formatMessage } = useIntl()
  const oauthRedirectUri = React.useMemo(() => {
    if (!signupId) return ''
    return getOauthRedirectUri(window.location.origin, signupId)
  }, [signupId])

  return (
    <Box>
      <Box>
        <Typography variant="body" paragraph>
          {formatMessage({ id: 'tlpay.in-step-1-you-will-need-to-copy-and-paste-the-provided-o-auth-redirect-uri-and-certificate-into-the-corresponding-fields-within-your-revolut-account-api-settings-these-credentials-are-essential-for-establishing-a-secure-connection-between-your-application-and-the-revolut-api' })}
        </Typography>
        <Typography variant="body" paragraph>
          {formatMessage({ id: 'tlpay.please-follow-the-below-steps' })}
        </Typography>
        <Box mt={1}>
          <Ol>
            <li>{formatMessage({ id: 'tlpay.log-in-to-your-revolut-business-account' })}</li>
            <li>{formatMessage({ id: 'tlpay.click-on-the-cog-icon-in-the-top-right-corner-of-the-screen' })}</li>
            <li>{formatMessage({ id: 'tlpay.select-api-from-the-menu' })}</li>
            <li>{formatMessage({ id: 'tlpay.ensure-the-business-api-tab-is-selected' })}</li>
            <li>{formatMessage({ id: 'tlpay.click-add-api-certificate' })}</li>
            <li>{formatMessage({ id: 'tlpay.paste-the-provided-certificate-into-the-certificate-field' })}</li>
            <li>{formatMessage({ id: 'tlpay.paste-the-provided-o-auth-redirect-uri-into-the-o-auth-redirect-uri-field' })}</li>
            <li>{formatMessage({ id: 'tlpay.click-continue-in-revolut-once-the-above-steps-have-been-complete' })}</li>
          </Ol>
        </Box>
      </Box>

      <Box mt={4}>
        <Typography variant="h4">
          {formatMessage({ id: 'tlpay.configure-oauth-redirect-uri' })}
        </Typography>
      </Box>

      <Box mt={2}>
        <TextField
          variant="outlined"
          label={formatMessage({ id: 'tlpay.oauth-redirect-uri' })}
          value={oauthRedirectUri}
          fullWidth
        />
      </Box>

      <Box mt={1}>
        <Button color="primary" onClick={async () => {
          await navigator.clipboard.writeText(oauthRedirectUri)
          toast.success(formatMessage({ id: 'tlpay.oauth-redirect-uri-copied' }), {
            autoClose: 2000,
          })
        }}>
          {formatMessage({ id: 'tlpay.copy' })}
        </Button>
      </Box>

      <Box mt={4}>
        <Typography variant="h4">
          {formatMessage({ id: 'tlpay.upload-certificate' })}
        </Typography>
      </Box>
      <Box display="flex" mt={2}>
        <textarea
          style={{
            resize: 'none',
            width: '100%',
            maxHeight: '10rem',
            boxSizing: 'border-box',
          }}
          rows={CERTIFICATE.split('\n').length + 1}
          value={CERTIFICATE}
        />
      </Box>
      <Box mt={1}>
        <Button color="primary" onClick={async () => {
          await navigator.clipboard.writeText(CERTIFICATE)
          toast.success(formatMessage({ id: 'tlpay.certificate-copied' }), {
            autoClose: 2000,
          })
        }}>
          {formatMessage({ id: 'tlpay.copy' })}
        </Button>
      </Box>

      <Box mt={4} display="flex" gridGap="1rem" justifyContent="flex-end">
        <Button onClick={() => onCancel?.()}>
          {formatMessage({ id: 'tlpay.back' })}
        </Button>
        <Button
          color="primary"
          onClick={() => onComplete?.()}
        >
          {formatMessage({ id: 'tlpay.continue' })}
        </Button>
      </Box>
    </Box>
  )
}

ConfigureRevolutSettings.propTypes = {
  signupId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ConfigureRevolutSettings
