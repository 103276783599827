import React ,{ useMemo, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, EWALLET_PROVIDER, EWALLET_STATUS, ROUTE_URL } from 'constants.js'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import AuthView from 'containers/AuthView/connect'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { getCompanyText, getRegionAndProvider } from './utils'
import NiumSignup from './NiumSignup'
import RevolutSignup from './RevolutSignup'
import { SIGNUP_STEPS } from './constants'
import { styled } from '@material-ui/core/styles'

const Ol = styled('ol')(({ theme }) => ({
  '& > li:not(:last-child)': {
    paddingBottom: theme.spacing(1),
  }
}))

function TLPayOpenNewAccount({
  location,
  auth,
  currUserCompany,
}) {
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const { formatMessage } = useIntl()
  const [companyWallet, setCompanyWallet] = useState()
  const [selectedOption, setSelectedOption] = useState(null)
  const {
    data: wallets,
    isLoading: isLoadingWallets,
    refetch: refetchWallets,
  } = useQuery('getAllWallets', () => get(`${API_URL}/e-wallet/wallets`), { retry: false })

  const eligibleCompanies = useMemo(() => {
    if (isLoadingWallets || !wallets?.length) return []
    return wallets
      .filter(w =>
        !w.wallets.find(ww => ww.status === EWALLET_STATUS.ACTIVE && ww.provider === EWALLET_PROVIDER.REVOLUT) || (
          getRegionAndProvider(w.countryAlpha2)?.provider &&
          !w.wallets.find(ww => ww.status === EWALLET_STATUS.ACTIVE && [EWALLET_PROVIDER.NIUM_EU, EWALLET_PROVIDER.NIUM_UK].includes(ww.provider))
        )
      )
  }, [wallets, isLoadingWallets])

  const companyOptions = useMemo(() => eligibleCompanies.map(w => ({
    id: w.companyId,
    label: getCompanyText(w),
  })), [eligibleCompanies])

  const showSelect = !isLoadingWallets && companyOptions.length > 1

  useEffect(() => {
    setGlobalSpinner(isLoadingWallets)
  }, [isLoadingWallets, setGlobalSpinner])

  useEffect(() => {
    // auto select
    const preselected = eligibleCompanies?.find(w => w.companyId === (companyWallet?.companyId || currUserCompany?.id))
    const w = preselected || eligibleCompanies[0]
    setCompanyWallet(w)

    // manage autocomplete value
    const optionToSelect = companyOptions.find(co => co.id === w?.companyId)
    setSelectedOption(optionToSelect)
  }, [
    companyOptions,
    eligibleCompanies,
    currUserCompany?.id,
    companyWallet?.companyId,
  ])

  if (isLoadingWallets) {
    return null
  }

  if (!eligibleCompanies.length) {
    return (<Redirect to={ROUTE_URL.tlpayAccounts} />)
  }

  const isEligibleToApplyNium = getRegionAndProvider(companyWallet?.countryAlpha2)?.provider &&
    !companyWallet?.wallets.some(ww => ww.status === EWALLET_STATUS.ACTIVE && [EWALLET_PROVIDER.NIUM_EU, EWALLET_PROVIDER.NIUM_UK].includes(ww.provider))
  const isEligibleToApplyRevolut = !companyWallet?.wallets.some(ww => ww.status === EWALLET_STATUS.ACTIVE && ww.provider === EWALLET_PROVIDER.REVOLUT)

  return (
    <AuthView location={location} auth={auth} title="TL Pay">
      <Grid style={{ marginBottom: '10px' }} container xs={12} alignItems='center'>
        <Typography variant='h1' gutterBottom>
          {formatMessage({ id: 'tlpay.tl-pay' })}
        </Typography>

        {showSelect && <Autocomplete
          options={companyOptions}
          variant="outlined"
          fullWidth
          dense
          name="select-company"
          value={selectedOption}
          getOptionLabel={(option) => option.label}
          onChange={(e, val) => {
            if(val && val.id) {
              setCompanyWallet(
                wallets?.find(w => w.companyId === val.id)
              )
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={formatMessage({ id: 'tlpay.my-tl-pay-accounts' })} variant="outlined" />
          )}
        ></Autocomplete>}
      </Grid>

      <Box mx={2} my={4}>
        <Typography variant="h2" color="primary">
          {formatMessage({ id: 'tlpay.welcome-to-your-tl-pay-account-application' })}
        </Typography>

        <Box mt={4}>
          <Typography variant="body" color="primary" paragraph>
            {formatMessage({ id: 'tlpay.tl-pay-is-a-secure-closed-loop-payment-platform-that-enables-fast-and-easy-payments-for-travel-companies-of-all-sizes-regardless-of-their-processing-entity-scheme' })}
          </Typography>
          <Typography variant="body" color="primary" paragraph>
            {formatMessage({ id: 'tlpay.tl-pay-uses-digital-banking-technology-to-allow-you-to-fund-your-account-within-the-travel-ledger-platform-this-enables-fast-settlements-reducing-settlement-times-by-over-50-compared-to-traditional-direct-debit-methods' })}
          </Typography>
          <Typography variant="body" color="primary" paragraph>
            {formatMessage({ id: 'tlpay.to-settle-on-the-platform-you-have-two-convenient-options' })}
          </Typography>
          <Box mb={2}>
            <Ol>
              <li>
                <Typography variant="body" color="primary">
                  {formatMessage({ id: 'tlpay.use-your-existing-revolut-account-or-create-a-new-revolut-account-by-visiting-the-revolut-website' })}
                </Typography>
              </li>
              <li>
                <Typography variant="body" color="primary">
                  {formatMessage({ id: 'tlpay.create-a-new-nium-emoney-account-to-receive-settlements' })}
                </Typography>
              </li>
            </Ol>
          </Box>
          <Typography variant="body" color="primary" paragraph>
            {formatMessage({ id: 'tlpay.please-select-your-preferred-method-below-to-ensure-smooth-and-efficient-payment-processing' })}
          </Typography>
        </Box>

        {isEligibleToApplyRevolut && (
          <Box mt={4}>
            <RevolutSignup companyWallet={companyWallet} refetchWallets={refetchWallets} />
          </Box>
        )}
        {isEligibleToApplyNium && (
          <Box mt={4}>
            <NiumSignup
              key={'nium-signup-' + companyWallet?.companyId}
              companyWallet={companyWallet}
              refetchWallets={refetchWallets}
              initStep={SIGNUP_STEPS.INTRODUCTION}
            />
          </Box>
        )}
      </Box>
    </AuthView>
  )
}

TLPayOpenNewAccount.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(TLPayOpenNewAccount)
