import { fullName } from 'utils/functions'

// Action Names
const FETCH_USERS = fullName('users', 'FETCH_USERS')
const FETCH_USERS_SUCCESS = fullName('users', 'FETCH_USERS_SUCCESS')
const RESET_MFA = fullName('users', 'RESET_MFA')
const RESET_MFA_SUCCESS = fullName('users', 'RESET_MFA_SUCCESS')
const RESET_MFA_FAILURE = fullName('users', 'RESET_MFA_FAILURE')
const INVITE_USER = fullName('users', 'INVITE_USER')
const INVITE_USER_SUCCESS = fullName('users', 'INVITE_USER_SUCCESS')
const INVITE_USER_FAILURE = fullName('users', 'INVITE_USER_FAILURE')
const TOGGLE_USER = fullName('users', 'TOGGLE_USER')
const TOGGLE_USER_SUCCESS = fullName('users', 'TOGGLE_USER_SUCCESS')
const TOGGLE_USER_FAILURE = fullName('users', 'TOGGLE_USER_FAILURE')
const CLOSE_SNACKBAR = fullName('users', 'CLOSE_SNACKBAR')
export const VALIDATE_INVITE_COMPANY = fullName('company-invites', 'VALIDATE_INVITE_COMPANY')
export const VALIDATE_INVITE_COMPANY_SUCCESS = fullName('company-invites', 'VALIDATE_INVITE_COMPANY_SUCCESS')
export const VALIDATE_INVITE_COMPANY_FAILURE = fullName('company-invites', 'VALIDATE_INVITE_COMPANY_FAILURE')
export const INVITE_COMPANY = fullName('company-invites', 'INVITE_COMPANY')
export const INVITE_COMPANY_SUCCESS = fullName('company-invites', 'INVITE_COMPANY_SUCCESS')
export const INVITE_COMPANY_FAILURE = fullName('company-invites', 'INVITE_COMPANY_FAILURE')
export const SET_FETCHING = fullName('fetching', 'FETCHING')

export const ACTIONS = {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_COMPANY,
  INVITE_COMPANY_SUCCESS,
  INVITE_COMPANY_FAILURE,
  RESET_MFA,
  RESET_MFA_SUCCESS,
  RESET_MFA_FAILURE,
  TOGGLE_USER,
  TOGGLE_USER_SUCCESS,
  TOGGLE_USER_FAILURE,
  SET_FETCHING,
  VALIDATE_INVITE_COMPANY,
  VALIDATE_INVITE_COMPANY_SUCCESS,
  VALIDATE_INVITE_COMPANY_FAILURE,
  CLOSE_SNACKBAR
}

export const fetchUsers = (companyId) => ({
  type: FETCH_USERS,
  payload: {
    companyId
  }
})

export const createInvite = (data) => ({
  type: INVITE_USER,
  payload: data
})

export const createCompanyInvite = (payload) => ({
  type: INVITE_COMPANY,
  payload
})

export const resetMFAUser = (email) => ({
  type: RESET_MFA,
  payload: email
})

export const deactivateUser = (id) => ({
  type: TOGGLE_USER,
  payload: {
    id,
    deactivate: true
  }
})

export const reactivateUser = (id) => ({
  type: TOGGLE_USER,
  payload: {
    id,
    deactivate: false
  }
})

export const validateCompanyInvite = (file) => ({
  type: VALIDATE_INVITE_COMPANY,
  payload: file
})

const initialState = {
  users: [],
  invites: [],
  failedInvites: [],
  totalFailedInvites: undefined,
  fetching: false,
  snackbar: {
    isOpened: false,
    isError: false,
    successOrErrorText: ''
  }
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        fetching: true
      }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    case INVITE_USER:
      return {
        ...state,
        snackbar: {
          isOpened: false,
          successOrErrorText: ''
        },
        fetching: true
      }
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: 'User invite successfully'
        }
      }
    case RESET_MFA:
      return {
        ...state,
        snackbar: {
          isOpened: false,
          successOrErrorText: ''
        },
        fetching: true
      }
    case RESET_MFA_SUCCESS:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: '2-factor authentication reset'
        }
      }
    case VALIDATE_INVITE_COMPANY:
    case INVITE_COMPANY:
      return {
        ...state,
        failedInvites: [],
        totalFailedInvites: undefined
      }
    case VALIDATE_INVITE_COMPANY_SUCCESS:
      return {
        ...state,
        failedInvites: action.payload?.errors ?? [],
        totalFailedInvites: action.payload?.total ?? undefined
      }
    case VALIDATE_INVITE_COMPANY_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case INVITE_COMPANY_SUCCESS:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: 'Company invite successfully',
          shouldReplaceWithCustomMessage: true,
        }
      }
    case INVITE_COMPANY_FAILURE:
      return {
        ...state,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload,
          shouldReplaceWithCustomMessage: true,
        }
      }
    case INVITE_USER_FAILURE:
    case RESET_MFA_FAILURE:
    case TOGGLE_USER_FAILURE:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: true,
          successOrErrorText: action.payload
        }
      }
    case TOGGLE_USER:
      return {
        ...state,
        snackbar: {
          isOpened: false,
          successOrErrorText: ''
        },
        fetching: true
      }
    case TOGGLE_USER_SUCCESS:
      return {
        ...state,
        fetching: false,
        snackbar: {
          isOpened: true,
          isError: false,
          successOrErrorText: `User ${action.payload ? 'de' : 're'}activated successfully`
        }
      }
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          isOpened: false,
          successOrErrorText: ''
        }
      }
    default:
      return state
  }
}

export default users
