import { Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import DownloadIcon from '@material-ui/icons/GetApp'
import Button from 'components/Button'
import LinkButton from 'components/LinkButton'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

const Group = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2)
}))

const PDFView = styled('div')(({ theme }) => ({
  height: '60vh',
  marginBottom: theme.spacing(1),
  overflowY: 'auto',
}))

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

const TermsAndConditions = ({ onComplete }) => {
  const fileUrl = '/terms-and-conditions-tlpay.pdf'
  const { formatMessage } = useIntl()
  const [numPages, setNumPages] = useState(null)
  const [accepted, setAccepted] = useState(false)

  return (
    <>
      <Typography variant='h3' gutterBottom>
        {formatMessage({ id: 'tlpay.terms-and-conditions' })}
      </Typography>
      <Typography paragraph>
        {formatMessage({ id: 'tlpay.please-review-and-accept-the-travel-ledger-t-c-addendum-relating-to-the-tl-pay-service-in-order-to-continue-activating-your-tl-pay-account' })}
      </Typography>
      <Group variant='outlined'>
        <PDFView>
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages: np }) => setNumPages(np)}
          >
            {new Array(numPages)
              .fill(null)
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  key={`page_${page}`}
                  pageNumber={page}
                  scale={1.5}
                  size="A4"
                />
              ))
            }
          </Document>
        </PDFView>
        <FlexRow>
          <FormControlLabel
            label={formatMessage({ id: 'tlpay.i-agree-to-the-tl-pay-terms-and-conditions' })}
            control={
              <Checkbox
                color="primary"
                checked={accepted}
                onChange={e => setAccepted(e.target.checked)}
              />
            }
          />

          <LinkButton
            color="primary"
            href={fileUrl}
            target='_blank'
            icon={<DownloadIcon />}
            iconPosition='end'
            label={formatMessage({ id: 'tlpay.download-travel-ledger-t-cs-pdf' })}
          />
        </FlexRow>
      </Group>
      <Button
        color="primary"
        onClick={onComplete}
        disabled={!accepted}
      >
        {formatMessage({ id: 'tlpay.continue' })}
      </Button>
    </>
  )
}

TermsAndConditions.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

export default TermsAndConditions
