import React ,{ useMemo, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import connect from './connect'
import { useQuery } from 'react-query'
import { get } from 'utils/api'
import { API_URL, EWALLET_STATUS, ROUTE_URL } from 'constants.js'
import { GlobalSpinnerActionsContext } from 'containers/App/components/GlobalSpinnerContextProvider'
import AuthView from 'containers/AuthView/connect'
import TLPay from './TLPay'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { getCompanyText } from './utils'

function TLPayAccounts({
  location,
  auth,
  currUserCompany,
}) {
  const setGlobalSpinner = useContext(GlobalSpinnerActionsContext)
  const { formatMessage } = useIntl()
  const [companyWallet, setCompanyWallet] = useState()
  const [selectedOption, setSelectedOption] = useState(null)
  const {
    data: wallets,
    isLoading: isLoadingWallets,
  } = useQuery('getAllWallets', () => get(`${API_URL}/e-wallet/wallets`), { retry: false })

  const activeWallets = useMemo(() => {
    if (isLoadingWallets || !wallets?.length) return []
    return wallets.filter(w => w?.wallets?.some(w0 => w0?.status === EWALLET_STATUS.ACTIVE))
  }, [wallets, isLoadingWallets])

  const companyOptions = useMemo(() => activeWallets.map(w => ({
    id: w.companyId,
    label: getCompanyText(w),
  })), [activeWallets])

  const showPage = !isLoadingWallets && !!companyWallet
  const showSelect = !isLoadingWallets && companyOptions.length > 1

  useEffect(() => {
    setGlobalSpinner(isLoadingWallets)
  }, [isLoadingWallets, setGlobalSpinner])

  useEffect(() => {
    // auto select
    const preselected = activeWallets?.find(w => w.companyId === (companyWallet?.companyId || currUserCompany?.id))
    const w = preselected || activeWallets[0]
    setCompanyWallet(w)

    // manage autocomplete value
    const optionToSelect = companyOptions.find(co => co.id === w?.companyId)
    setSelectedOption(optionToSelect)
  }, [
    companyOptions,
    activeWallets,
    currUserCompany?.id,
    companyWallet?.companyId,
  ])

  if (isLoadingWallets) {
    return null
  }

  if (!activeWallets.length) {
    return (<Redirect to={ROUTE_URL.tlpayOpenNewAccount} />)
  }

  return (
    <AuthView location={location} auth={auth} title="TL Pay">
      <Grid style={{ marginBottom: '10px' }} container xs={12} alignItems='center'>
        <Typography variant='h1' gutterBottom>
          {formatMessage({ id: 'tlpay.tl-pay' })}
        </Typography>

        {showSelect && <Autocomplete
          options={companyOptions}
          variant="outlined"
          fullWidth
          dense
          name="select-company"
          value={selectedOption}
          getOptionLabel={(option) => option.label}
          onChange={(e, val) => {
            if(val && val.id) {
              setCompanyWallet(
                wallets?.find(w => w.companyId === val.id)
              )
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={formatMessage({ id: 'tlpay.my-tl-pay-accounts' })} variant="outlined" />
          )}
        ></Autocomplete>}
      </Grid>

      {showPage && <TLPay companyWallet={companyWallet} />}
    </AuthView>
  )
}

TLPayAccounts.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  currUserCompany: PropTypes.object,
}

export default connect(TLPayAccounts)
