import { InstantPaymentStatus } from '@travel-ledger/type-constants'
import { COMPANY_ID_TYPES } from 'constants.js'

export const filterValuesToQueryParams = (filterValues) => {
  const filtersToSet = {}

  for (const dateField of [
    'requestedAt',
    'confirmedAt',
    'refundedAt',
    'reversedAt',
    'departureDate',
  ]) {
    if (filterValues[dateField]?.startDate) {
      filtersToSet[`${dateField}[gte]`] = filterValues[dateField].startDate
    }
    if (filterValues[dateField]?.endDate) {
      filtersToSet[`${dateField}[lte]`] = filterValues[dateField].endDate
    }
  }

  if (Object.values(InstantPaymentStatus).includes(filterValues.status)) {
    filtersToSet['status[eq]'] = filterValues.status
  }

  for (const amountField of [
    'amountInCents',
    'refundAmountInCents',
    'actualAmount',
    'bookingSellAmount',
    'bookingComAmount',
    'bookingNettAmount',
    'bookingSellTaxAmount',
    'bookingComTaxAmount',
    'bookingNettTaxAmount',
  ]) {
    if (filterValues[amountField]?.value) {
      // NOTE: The search value is not in cents
      if (filterValues[amountField]?.type === 'gte') {
        filtersToSet[`${amountField}[gte]`] = filterValues[amountField].value
      } else if (filterValues[amountField]?.type === 'lte') {
        filtersToSet[`${amountField}[lte]`] = filterValues[amountField].value
      } else if (filterValues[amountField]?.type === 'eq') {
        filtersToSet[`${amountField}[eq]`] = filterValues[amountField].value
      }
    }
  }

  if (Object.values(COMPANY_ID_TYPES).includes(filterValues.payerIdType)) {
    filtersToSet['payerIdType[eq]'] = filterValues.payerIdType
  }

  if (Object.values(COMPANY_ID_TYPES).includes(filterValues.payeeIdType)) {
    filtersToSet['payeeIdType[eq]'] = filterValues.payeeIdType
  }

  if (filterValues.payerIdReq) {
    filtersToSet['payerIdReq[startsWith]'] = filterValues.payerIdReq
  }

  if (filterValues.payeeIdReq) {
    filtersToSet['payeeIdReq[startsWith]'] = filterValues.payeeIdReq
  }

  if (filterValues.paymentTransactionId) {
    filtersToSet['paymentTransactionId[startsWith]'] = filterValues.paymentTransactionId
  }

  if (filterValues.paymentTransactionExternalId) {
    filtersToSet['paymentTransactionExternalId[startsWith]'] = filterValues.paymentTransactionExternalId
  }

  if (filterValues.bookingRef) {
    filtersToSet['bookingRef[startsWith]'] = filterValues.bookingRef
  }

  if (filterValues.refundTransactionId) {
    filtersToSet['refundTransactionId[startsWith]'] = filterValues.refundTransactionId
  }

  if (filterValues.refundTransactionExternalId) {
    filtersToSet['refundTransactionExternalId[startsWith]'] = filterValues.refundTransactionExternalId
  }

  if (filterValues.reverseTransactionId) {
    filtersToSet['reverseTransactionId[startsWith]'] = filterValues.reverseTransactionId
  }

  if (filterValues.reverseTransactionExternalId) {
    filtersToSet['reverseTransactionExternalId[startsWith]'] = filterValues.reverseTransactionExternalId
  }

  if (filterValues.currency?.length > 0) {
    filtersToSet['currency[in]'] = filterValues.currency
  }

  if (filterValues.transType && filterValues.transType !== 'ALL') {
    filtersToSet['transType[eq]'] = filterValues.transType
  }

  if (!Number.isNaN(parseInt(filterValues.transPaxNo))) {
    filtersToSet['transPaxNo[eq]'] = filterValues.transPaxNo
  }

  for (const searchField of [
    'leadName',
    'description',
    'optional1',
    'optional2',
    'optional3',
    'destCountry',
    'destination',
    'transProductType',
    'confNumber',
    'tktVchrNumber',
    'buyerNotes',
    'sellerNotes',
    'flightNumbers',
    'airlineCodes',
    'bookedBy',
  ]) {
    if (filterValues[searchField]?.trim()) {
      filtersToSet[`${searchField}[search]`] = filterValues[searchField].trim()
    }
  }

  return filtersToSet
}
